import React, { useState } from 'react'
import {
    AcceptButton,
    ButtonsArea,
    ButtonWrapper,
    Divider,
    MiddleWrapper,
    TextWrapper,
    Wrapper,
} from './styled'
import PropTypes from 'prop-types'
import { restorePassword } from '../../services/PunterService'
import { dark, defaultTheme } from '../../utils/loginThemes'
import TopBarLogin from './Components/TopBarLogin'
import { ThemeProvider } from 'styled-components'
import Text from '../Text'
import Spinner from '../Spinner'
import ValidatingInput, {
    cutEmptyValues,
} from '../ValidInput/ValidatingInputnNew'

const themesMap = {
    default: defaultTheme,
    dark,
}

/**
 * @param theme {string} select theme
 * @param site {string} project name
 * @param oldAPI {boolean} old API path
 * @param closeAction {function} close button handler
 * @param userProfile {object} User data
 * @param successHandler {function} handle success action
 * @param onError {function} handle error action
 * */
const RestorePassword = ({
    theme = 'default',
    site = '',
    oldAPI = false,
    closeAction,
    userProfile = {},
    successHandler,
    onError,
}) => {
    const [userData, setUserData] = useState(userProfile)
    const [passwordRef, setPasswordRef] = useState(null)
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [passwordStrength, setPasswordStrength] = useState(0)
    const [passwordHint, setPasswordHint] = useState('Minimum 10 tegn')
    const [errors, setErrors] = useState({
        email: '',
        password: false,
        confirmPassword: false,
    })
    const [close, setClose] = useState(false)
    const [loading, setLoading] = useState(false)
    const currentTheme = themesMap[theme]

    const isDataValid =
        userData.password &&
        userData.confirmPassword &&
        !errors.password &&
        !errors.confirmPassword

    const checkValue = (error) => {
        if (error) {
            setErrors({ ...errors, [error.name]: error.value })
        }
    }

    const showPassword = () => {
        setIsPasswordHidden(!isPasswordHidden)
    }

    const passwordChangeHandler = (value) => {
        value = cutEmptyValues(value)
        let strength = 0
        const hasNumber = /\d/
        function hasUpperCase(str) {
            return str.toLowerCase() !== str
        }

        if (value.length < 9) {
            setPasswordHint('Minimum 10 tegn')
        } else if (!hasUpperCase(value)) {
            setPasswordHint('Minimum ét STORT bogstav')
        } else if (!hasNumber.test(value)) {
            setPasswordHint('Minimum ét tal')
        } else {
            setPasswordHint('')
        }

        if (hasNumber.test(value)) strength = strength + 1
        if (hasUpperCase(value)) strength = strength + 1
        if (value.length >= 10) strength = strength + 1

        setPasswordStrength(strength)
        setUserData({ ...userData, password: value })
    }

    const onConfirmPasswordChange = (value) => {
        setUserData({ ...userData, confirmPassword: value })
    }

    const onClose = (trackAction) => {
        setClose(true)
        setTimeout(() => {
            closeAction()
            if (trackAction) {
                window.dataLayer.push({
                    event: 'restorePasswordClose',
                })
            }
        }, 200)
    }

    const buttonClickHandler = () => {
        setLoading(true)
        if (isDataValid) {
            restorePassword(
                site,
                loading,
                (value) => setLoading(value),
                oldAPI,
                userData.password,
                userData.confirmPassword,
                () => {
                    successHandler()
                    onClose()
                },
                onError
            )
        } else {
            setLoading(false)

            passwordRef.current &&
                passwordRef.current.blur &&
                passwordRef.current.blur()
        }
    }

    return (
        <ThemeProvider theme={currentTheme}>
            <Wrapper
                bg={currentTheme.colors.bg}
                close={close}
                key={`close-animation-${close}`}
            >
                <TopBarLogin
                    title={'Opret et nyt kodeord'}
                    currentTheme={currentTheme}
                    closeAction={() => onClose(true)}
                />

                <MiddleWrapper color={currentTheme.colors.basic}>
                    <Text style={{ marginTop: 0 }}>
                        Indtast et nyt kodeord for din konto nedenfor. Husk at
                        vælge en stærk kode for at beskytte dine oplysninger.
                    </Text>
                    <ValidatingInput
                        type="text"
                        title="Brugernavn"
                        name="userName"
                        value={userData.userName}
                        disabled
                        theme={theme}
                    />
                    <ValidatingInput
                        autoFocus={true}
                        onChangeHandler={passwordChangeHandler}
                        onCheckValue={checkValue}
                        type={isPasswordHidden ? 'password' : 'text'}
                        value={userData.password}
                        title="Nyt kodeord"
                        autoComplete="new-password"
                        validate="required, password"
                        min={10}
                        max={15}
                        name="password"
                        bottomLabel={passwordHint}
                        altLabel={isPasswordHidden ? 'Vis' : 'Skjul'}
                        altLabelClickHandler={showPassword}
                        passwordStrength={passwordStrength}
                        forwardedRef={(ref) => setPasswordRef(ref)}
                        theme={theme}
                    />
                    <ValidatingInput
                        onChangeHandler={onConfirmPasswordChange}
                        onCheckValue={checkValue}
                        type={isPasswordHidden ? 'password' : 'text'}
                        value={userData.confirmPassword}
                        title="Gentag ny kodeord"
                        autoComplete="new-password"
                        validate="required, sameas"
                        sameAs={userData.password}
                        min={10}
                        max={15}
                        disabled={!!errors.password || !userData.password}
                        name="confirmPassword"
                        altLabel={isPasswordHidden ? 'Vis' : 'Skjul'}
                        altLabelClickHandler={showPassword}
                        theme={theme}
                    />
                </MiddleWrapper>
                <ButtonsArea bg={currentTheme.colors.bg}>
                    <TextWrapper>
                        <Divider color={currentTheme.colors.divider} />
                    </TextWrapper>
                    <ButtonWrapper>
                        <AcceptButton
                            color={currentTheme.colors.basic}
                            activeColor={currentTheme.colors.activeLoginColor}
                            disableColor={
                                currentTheme.colors.disabledLoginColor
                            }
                            onClick={() => buttonClickHandler()}
                        >
                            <Text
                                color={currentTheme.colors.basic}
                                bold={currentTheme.bold}
                                condensed={currentTheme.condensed}
                                align={'center'}
                            >
                                Gem
                            </Text>
                            {loading && <Spinner color={'#fff'} size={'md'} />}
                        </AcceptButton>
                    </ButtonWrapper>
                </ButtonsArea>
            </Wrapper>
        </ThemeProvider>
    )
}

RestorePassword.propTypes = {
    theme: PropTypes.string,
    site: PropTypes.string,
    oldAPI: PropTypes.bool,
    closeAction: PropTypes.func,
    userProfile: PropTypes.object,
    successHandler: PropTypes.func,
    onError: PropTypes.func,
}

export default RestorePassword
