import React from 'react'
import { TransferMoneyIconWrapper, TransferMoneyModalWrapper } from './styled'
import CloseButtonIcon from '../../CloseButton/CloseButton'
import WalletIcon from '../../../images/icons/wallet_transparent.svg'
import Icon from '../../Icon'
import { numberWithCommasAndDots } from '../../../utils/formatters'
import Text from '../../Text'
import Button from '../../../components/Button'

const TransferMoneyModal = ({ amount = 0, closeModal = () => {} }) => {
    return (
        <TransferMoneyModalWrapper>
            <CloseButtonIcon click={closeModal} top={'36px'} />
            <TransferMoneyIconWrapper>
                <Icon src={WalletIcon} height={'75px'} width={'75px'} />
            </TransferMoneyIconWrapper>

            <Text margin={'0'} padding={'0'} size={18} lineHeight={'28px'} bold color={'#1E1E1E'} align={'center'}>
                Udbetaling på {numberWithCommasAndDots(amount)} kr. Bekræftet!
            </Text>
            <Text size={13} lineHeight={'20px'} margin={'16px 0 0'} padding={'0'} color={'#4F555E'} align={'center'}>
                Vi forventer, at din gevinst er på din NemKonto<br/>
                indenfor 1-2 bankdage.
            </Text>
            <hr/>
            <Button fontWeight={'bold'} color={'#1E1E1E'} bgColor={'#fbd600'} radius={'4px'} onClick={closeModal}>
                Ok
            </Button>
        </TransferMoneyModalWrapper>
    )
}

export default TransferMoneyModal
