import React, { Fragment, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import LottiePlayer from 'react-lottie-player/dist/LottiePlayerLight'

import Text from './../../../Text'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import {
    dateFormatter,
    numberWithCommasAndDotsIntl,
} from '../../../../utils/formatters'
import Icon from '../../../Icon'
import notFoundAnimation from '../../../../images/lootie-animations/not-found-animation.json'

import arrowRight from '../../../../images/icons/arrowRight.svg'
import Spinner from '../../../Spinner'
import Button from '../../../Button'
import Flex from '../../../Flex'

export const dissolve = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const Wrapper = styled.div`
    overflow-y: auto;
    flex-grow: 1;
    padding-bottom: 10px;
    padding-top: 80px;
    text-align: center;
    /* autoprefixer ignore next */
    -webkit-overflow-scrolling: touch; //fixes scroll on iOS

    /* Remove Scrolling */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`
const TransactionsHeading = styled.h5`
    color: ${(props) => props.theme.colors.colorTextPrimaryDefault};
    font-size: 20px;
    padding: 0;
    margin: 0 0 15px;
    text-align: left;
    font-weight: ${(props) => props.theme.fontWeight};
`

const TransactionsList = styled.div`
    display: block;
    border-radius: 8px;
    overflow: hidden;
`

const GroupItem = styled.div`
    display: flex;
    background: ${(props) =>
        props.theme.colors.colorBackgroundSurfacePrimaryDefault};
    color: ${(props) => props.theme.colors.colorTextPrimaryDefault};
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    ${({ border, theme }) =>
        border &&
        `border-bottom: 1px solid ${theme.colors.colorBackgroundSurfaceTertiaryDefault}`};
    cursor: pointer;
    animation: ${dissolve} 300ms ease-out;
    transition: transform 300ms ease-out;
    animation-delay: ${(props) => props.delay}s;

    &:hover {
        background: ${(props) =>
            props.theme.colors.colorBackgroundSurfaceTertiaryHover};
    }
`

const GroupRow = styled.div`
    height: auto;
    text-align: ${({ align }) => (align ? align : 'left')};
    ${({ flex }) => `flex: ${flex}`};
`

const StyledText = styled(Text)`
    font-size: 13px;
    line-height: normal;
    color: ${(props) =>
        props.color ? props.color : props.theme.colors.colorTextPrimaryDefault};
    margin: 0;
`

const TextLabel = styled.span`
    color: ${(props) =>
        props.color
            ? props.color
            : props.theme.colors.colorTextTertiaryDefault};
`

export const Link = styled.span`
    text-align: center;
    cursor: pointer;
    font-weight: ${(props) => props.theme.fontWeight};
    text-decoration: underline;
    color: ${(props) => props.theme.colors.colorTextUtilitiesLink};
`

const TransactionList = ({
    data = [],
    theme,
    groupBy = 'MMMM',
    loading,
    showMore,
    periodDate = [],
    transactionDataFormat = 'DDMMYYYY',
    showDetails = () => {},
    loadMoreHandler = () => {},
    loadMoreColor = '',
    tab = '',
}) => {
    const [sortedData, setSortedData] = useState(null)
    const [periodTitle, setPeriodTitle] = useState('')
    const contentRef = useRef(null)

    const handleScroll = () => {
        if (contentRef.current) {
            contentRef.current.scrollTop = 0
        }
    }

    useEffect(() => {
        handleScroll()
    }, [tab])

    useEffect(() => {
        if (periodDate.length) {
            const fromDate = moment(
                periodDate[0],
                transactionDataFormat
            ).format('DD. MMM YYYY')
            const toDate = moment(periodDate[1], transactionDataFormat).format(
                'DD. MMM YYYY'
            )
            setPeriodTitle(`${fromDate} - ${toDate}`)
            setSortedData(data)
        } else {
            setPeriodTitle('')
            setSortedData(data)
        }
    }, [periodDate, groupBy, data])

    const groupItem = (transaction) => {
        return (
            <Fragment>
                <GroupRow flex={6}>
                    <StyledText style={{ margin: '0 0 4px' }}>
                        {transaction.text}{' '}
                        <TextLabel>{transaction.product}</TextLabel>
                    </StyledText>
                    <StyledText>
                        {dateFormatter(
                            new Date(
                                transaction.transactionDate
                            ).toLocaleString('da-DK', {
                                dateStyle: 'short',
                                timeStyle: 'short',
                            })
                        )}
                    </StyledText>
                </GroupRow>
                <GroupRow flex={3}>
                    <StyledText
                        align={'right'}
                        color={
                            transaction.amount >= 0
                                ? theme.colors.colorTextSuccessDefault
                                : null //--color-text-success-default
                        }
                        style={{ margin: '0 0 4px' }}
                    >
                        {numberWithCommasAndDotsIntl(transaction.amount)} kr.
                    </StyledText>
                    <StyledText align={'right'}>
                        {numberWithCommasAndDotsIntl(transaction.balance)} kr.
                    </StyledText>
                </GroupRow>
                <GroupRow flex={1} align={'right'}>
                    <Icon
                        src={arrowRight}
                        width={'16px'}
                        height={'16px'}
                        color={theme.colors.colorIconSecondaryDefault}
                    />
                </GroupRow>
            </Fragment>
        )
    }

    return (
        <Wrapper ref={contentRef}>
            <TransactionsHeading>
                {periodTitle ? periodTitle : 'Seneste transaktioner'}
            </TransactionsHeading>
            {data.length ? (
                <Fragment>
                    <TransactionsList>
                        {sortedData &&
                            sortedData
                                .sort(
                                    (a, b) =>
                                        new Date(b.transactionDate) -
                                        new Date(a.transactionDate)
                                )
                                .map((transaction, index) => (
                                    <GroupItem
                                        key={index}
                                        border={index < sortedData.length - 1}
                                        onClick={() => showDetails(transaction)}
                                        //delay={index * 0.1}
                                    >
                                        {groupItem(transaction)}
                                    </GroupItem>
                                ))}
                    </TransactionsList>
                    {showMore && (
                        <Flex style={{ margin: '16px 0' }}>
                            <Button
                                maxWidth={'352px'}
                                bgColor={
                                    loadMoreColor ||
                                    theme.colors
                                        .colorBackgroundSurfacePrimaryDefault
                                }
                                borderColor={
                                    theme.colors.colorBorderPrimaryDefault
                                }
                                onClick={loadMoreHandler}
                                disabled={loading}
                            >
                                <Text
                                    color={theme.colors.colorTextPrimaryDefault}
                                    align={'center'}
                                >
                                    Vis mere
                                </Text>
                                {loading && (
                                    <Spinner
                                        color={
                                            theme.colors.colorTextPrimaryDefault
                                        }
                                        size={'md'}
                                    />
                                )}
                            </Button>
                        </Flex>
                    )}
                </Fragment>
            ) : loading ? (
                <Spinner size={'md'} style={{ textAlign: 'center' }} />
            ) : (
                <Flex direction={'column'} style={{ margin: '0 0 32px' }}>
                    <LottiePlayer
                        loop
                        animationData={notFoundAnimation}
                        play
                        style={{
                            width: '100px',
                            height: '100px',
                            margin: '0 auto',
                        }}
                    />
                    <Text color={theme.colors.colorTextTertiaryDefault}>
                        Ingen transaktioner fundet
                    </Text>
                </Flex>
            )}
            <Flex
                background={theme.colors.colorBackgroundSurfaceSecondaryDefault}
                borderRadius={'8px'}
                align={'baseline'}
                justify={'flex-start'}
                direction={'column'}
                style={{ padding: '24px', margin: '16px 0' }}
            >
                <Text
                    size={18}
                    weight={theme.fontWeight}
                    color={theme.colors.colorTextPrimaryDefault}
                    margin={'0 0 16px 0'}
                >
                    Leder du efter noget specifikt?
                </Text>
                <Text
                    size={13}
                    lineHeight={'20px'}
                    margin={'0'}
                    color={theme.colors.colorTextPrimaryDefault}
                >
                    For en fuld transaktionsoversigt, eller en liste af
                    transaktioner for et givet tidsrum bedes du rette
                </Text>
                <Text
                    size={13}
                    lineHeight={'20px'}
                    margin={'0'}
                    color={theme.colors.colorTextPrimaryDefault}
                >
                    henvendelse til vores{' '}
                    <Link onClick={() => window.Intercom('show')} bold>
                        kundeservice
                    </Link>
                </Text>
            </Flex>
        </Wrapper>
    )
}

TransactionList.propTypes = {
    data: PropTypes.array.isRequired,
    theme: PropTypes.object,
    groupBy: PropTypes.string,
    loading: PropTypes.bool,
    showMore: PropTypes.bool,
    transactionDataFormat: PropTypes.string,
    periodDate: PropTypes.array,
    showDetails: PropTypes.func,
    loadMoreHandler: PropTypes.func,
}

export default TransactionList
