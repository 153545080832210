import styled from 'styled-components'
import { BonusButton } from '../styled'

export const Wrapper = styled.div`
    width: 100%;
    max-width: 375px;
    background: #F5F8FC;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const StyledModalTopbar = styled.div`
    text-align: center;
    padding: 24px 0 ;
    color: #000;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    position: relative;
`

export const ContentWrapper = styled.div`
    padding: 0 24px 0 ;
    color: #4F555E;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    hr {
        margin: 16px 0 0;
        border: none;
        outline: none;
        border-top: 1px solid #DFE5ED;
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 16px 24px 24px;
`

export const CancelButton = styled(BonusButton)`
    background: #F5F8FC;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #DFE5ED;
    transition: all 0.4s ease-in-out;
    &:hover {
        background: #DFE5ED;
    }
`

export const ConfirmButton = styled(BonusButton)`
    background: #FEF1F1;
    color: #C41313;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.4s ease-in-out;
    &:hover {
        background: #C41313;
        color: #ffffff;
    }
`
