import styled, {css} from 'styled-components'

const Button = styled.button`
    display: flex;
    position: relative;
    height: 44px;
    width: 100%;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
    border-radius: ${({radius}) => radius || '8px'};
    outline: none;
    border: none;
    font-size: ${(props) => (props.fontSize || props.theme.fontSize)};
    font-weight: ${(props) => (props.fontWeight || props.theme.fontWeight)};
    font-family: ${(props) => props.theme.fontFamily};
    transition: all 0.3s ease;
    color: ${(props) => props.color ? props.color : props.theme.colors.colorTextPrimaryDefault};
    background: ${(props) => props.bgColor ? props.bgColor : props.theme.colors.activeBtnColor};
    border: ${(props) =>
    props.borderColor
        ? '1px solid ' + props.borderColor
        : '1px solid transparent'};
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 8px;
    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.4;
            cursor: not-allowed;
        `};
`
export default Button
