import React from 'react'
import walletIcon from '../../../images/icons/wallet.svg'
import Icon from '../../Icon'
import { WalletIconWrapper, WalletLeft, WalletRight, WalletWrapper, GrayText } from './styled'
import Text from '../../Text'
import { numberWithCommasAndDotsIntl } from '../../../utils/formatters'

const Wallet = ({theme, userData, bonus}) => {
    return (
        <WalletWrapper>
            <WalletLeft>
                <WalletIconWrapper bC={theme.bg.info_background_100}>
                    <Icon
                        height={'12px'}
                        width={'12px'}
                        mt={0}
                        mb={0}
                        src={walletIcon}
                        color={theme.info.icon_content_300}
                        my={2}
                    />
                </WalletIconWrapper>
                <Text
                    margin={'0'}
                    padding={'0'}
                    size={13}
                    lineHeight={'20px'}
                    color={theme.colors.black_font_900}
                >
                    <b>Saldo</b>
                    <br />
                    <GrayText color={theme.colors.placeholder}>
                        Bonus saldo
                    </GrayText>
                </Text>
            </WalletLeft>
            <WalletRight>
                <Text
                    margin={'0'}
                    padding={'0'}
                    size={13}
                    lineHeight={'20px'}
                    align={'right'}
                    color={theme.colors.black_font_900}
                >
                    <b> {numberWithCommasAndDotsIntl(userData.balance, true)} kr.</b>
                    <br />
                    <GrayText color={theme.colors.placeholder}>
                        {numberWithCommasAndDotsIntl(bonus, true)} kr.
                    </GrayText>
                </Text>
            </WalletRight>
        </WalletWrapper>
    )
}

export default Wallet;
