import React, { useState, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/da'
import styled, { ThemeProvider } from 'styled-components'

import { ProfileCard, AddressChangeInfo } from './styled'

import ValidatingInput from '../../ValidInput/ValidatingInputnNew'
import Spinner from '../../Spinner'
import Text from '../../Text'
import Button from '../../Button'

import { cutWhiteSpaces } from '../../../utils/formatters'
import { themesMap } from '../../../theme'
import {
    checkExistingEmail,
    getAccountInformation,
    setAccountInformation,
} from '../../../services/PunterService'
import Flex from '../../Flex'
import { Link } from '../UserHistory/Components/TransactionList'
import { APPLY_BUTTON_MAX_WIDTH, ButtonsArea } from '../styled'

const Wrapper = styled.div`
    padding: 20px;
`

const ProfilePage = ({
    currentTheme = 'default',
    supportEmail,
    showSuccess = () => {},
    showError = () => {},
    punterSalt = '',
}) => {
    const [profile, setProfileData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()

    const [updateLoading, setUpdateLoading] = useState(false)

    const [errors, setErrors] = useState({
        email: false,
        phone: false,
    })

    const formHasErrors = Object.values(errors).some(
        (fieldInvalid) => fieldInvalid
    )
    const [formTouched, setFormTouched] = useState(false)
    const [emailTouched, setEmailTouched] = useState(false)

    const checkValue = (error) => {
        if (error) {
            setErrors({ ...errors, [error.name]: error.value })
        }
    }

    useEffect(() => {
        setLoading(true)

        getAccountInformation()
            .then((profileData) => {
                setProfileData(profileData)
                setEmail(profileData.email)
                setPhone(
                    profileData.phoneNr
                        ? profileData.phoneNr.substring(0, 8)
                        : ''
                )
            })
            .catch((err) => showError(err.toString()))
            .finally(() => setLoading(false))
    }, [])

    const disallowFormSubmit = formHasErrors || !formTouched

    const maybeCheckExistingEmail = () => {
        // should not validate email that user just put by himself and probably saved changes @see https://ecosys.atlassian.net/browse/DER-4451
        if (!emailTouched) {
            return Promise.resolve()
        }

        return checkExistingEmail({ email, salt: punterSalt }).then((res) => {
            return res && res.data && res.data.exist
                ? Promise.reject(`Denne e-mail er allerede i brug.`)
                : Promise.resolve()
        })
    }

    const submitProfile = () => {
        if (disallowFormSubmit || updateLoading) {
            return
        }

        setUpdateLoading(true)

        maybeCheckExistingEmail()
            .then(() => setAccountInformation({ email, phoneNr: phone }))
            .then(() => {
                showSuccess()
                setFormTouched(false)
                setEmailTouched(false)
            })
            .catch((err) => showError(err))
            .finally(() => setUpdateLoading(false))
    }

    if (loading) {
        return (
            <Flex>
                <Spinner color={'#000'} size={'md'} />
            </Flex>
        )
    }

    const loginTime =
        profile && profile.extraInfo && profile.extraInfo.loginTime
            ? moment(profile.extraInfo.loginTime)
                  .locale('da')
                  .format('[Sidste log ind:] D. MMMM YYYY, [kl.] HH:mm:ss')
            : ''

    const handleChangeEmail = (value) => {
        setFormTouched(true)
        setEmailTouched(true)
        setEmail(cutWhiteSpaces(value))
    }

    const handleChangePhone = (value) => {
        setFormTouched(true)
        setPhone(value)
    }

    const theme = themesMap[currentTheme]

    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <Text size={18} weight="900" padding="5px 0 5px 5px" margin="0">
                    {profile && profile.firstName} {profile && profile.lastName}
                </Text>
                <Text
                    size={14}
                    color={theme.colors.colorTextSecondaryDefault}
                    padding="5px 0 5px 5px"
                    margin="0"
                >
                    {loginTime}
                </Text>

                <ValidatingInput
                    autoFocus
                    type="text"
                    title="Email"
                    name="email"
                    autoComplete="new-email"
                    onCheckValue={checkValue}
                    validate="required, email"
                    value={email}
                    onChangeHandler={handleChangeEmail}
                    validateOnChange
                    successBorderColor={theme.colors.colorBorderSecondaryHover}
                    disabled={loading}
                />

                <ValidatingInput
                    onChangeHandler={handleChangePhone}
                    onCheckValue={checkValue}
                    type="number"
                    title="Telefonnummer"
                    validate="required, min, max, numeric"
                    min={8}
                    max={8}
                    value={phone}
                    name="phone"
                    placeholder="+45"
                    autoComplete="new-phone-number"
                    allowEmptyFormatting
                    format="+45 ## ## ## ##"
                    validateOnChange
                    successBorderColor={theme.colors.colorBorderSecondaryHover}
                    disabled={loading}
                />

                <ProfileCard>
                    <div className="pb-5">
                        Navn: {profile && profile.firstName}{' '}
                        {profile && profile.lastName}
                    </div>
                    <div className="pb-5">
                        Adresse: {profile && profile.address},{' '}
                        {profile && profile.zip} {profile && profile.city}
                    </div>
                    <div className="pb-5">
                        Brugernavn: {profile && profile.userName}
                    </div>

                    <AddressChangeInfo>
                        Har du ændret navn eller adresse bedes du kontakte vores{' '}
                        <Link onClick={() => window.Intercom('show')} bold>
                            support
                        </Link>{' '}
                        for at ændre dette.
                    </AddressChangeInfo>
                </ProfileCard>

                <ButtonsArea>
                    <Button
                        maxWidth={APPLY_BUTTON_MAX_WIDTH}
                        disabled={disallowFormSubmit}
                        onClick={submitProfile}
                        fontSize={'13px'}
                        fontWeight={700}
                    >
                        {updateLoading ? (
                            <Spinner color={'#000'} size={'md'} />
                        ) : (
                            'Gem og forsæt'
                        )}
                    </Button>
                </ButtonsArea>
            </Wrapper>
        </ThemeProvider>
    )
}

export default ProfilePage
