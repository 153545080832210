import React, { useEffect, useState } from 'react'
import { MiddleWrapper, Wrapper } from './styled'
import PropTypes from 'prop-types'
import { login, LOGIN_TYPES } from '../../services/PunterService'
import { dark, defaultTheme } from '../../utils/loginThemes'
import UsernameLogin from '../UsernameLogin'
import TopBarLogin from './Components/TopBarLogin'
import ButtonArea from './Components/ButtonArea'

const themesMap = {
    default: defaultTheme,
    dark,
}

/**
 * @param isMitIdLogin {boolean} MitID flag active or not
 * @param closeAction {function} close button handler
 * @param loginHandler {function} Login button handler
 * @param forgotPassHandler {function} Forgot Password button handler
 * @param stayOnPrevPage {boolean} should we stay on same page after login
 * @param beforeLogin {function} before login handler function
 * @param theme {string} select theme
 * @param site {string} project name
 * @param oldAPI {boolean} old API path
 * @param showResetPassword {boolean} should we show Forgot Password button
 * */
const Login = ({
    isMitIdLogin = false,
    closeAction,
    loginHandler = () => {},
    forgotPassHandler = () => {},
    stayOnPrevPage = false,
    beforeLogin = () => {},
    theme = 'default',
    site = '',
    oldAPI = false,
    showResetPassword = false,
}) => {
    const [mitIdLoading, setMitIdLoading] = useState({
        [LOGIN_TYPES.MIT_ID]: false,
    })
    const [close, setClose] = useState(false)
    const currentTheme = themesMap[theme]

    useEffect(() => {
        isMitIdLogin && alternateLogin(LOGIN_TYPES.MIT_ID)
    })

    const onClose = () => {
        setClose(true)
        setTimeout(() => {
            closeAction()
        }, 200)
    }

    const alternateLogin = (type) => {
        beforeLogin(type)

        if (stayOnPrevPage) {
            localStorage.setItem('stay_on_prev_page', window.location.pathname)
        }

        login(
            type,
            site,
            mitIdLoading,
            (value) => setMitIdLoading(value),
            oldAPI,
            ''
        )
    }

    const forgotPassword = () => {
        alternateLogin(LOGIN_TYPES.MIT_ID)
    }

    return (
        <Wrapper
            bg={currentTheme.colors.bg}
            close={close}
            key={`close-animation-${close}`}
        >
            <TopBarLogin
                title={'Log ind'}
                currentTheme={currentTheme}
                closeAction={onClose}
            />

            <MiddleWrapper>
                <UsernameLogin
                    currentTheme={currentTheme}
                    forgotPassword={forgotPassword}
                    loginHandler={loginHandler}
                    forgotPassHandler={forgotPassHandler}
                    showResetPassword={showResetPassword}
                />
            </MiddleWrapper>
            <ButtonArea
                title={'eller'}
                currentTheme={currentTheme}
                btnLoading={mitIdLoading}
                buttonClickHandler={alternateLogin}
            />
        </Wrapper>
    )
}

Login.propTypes = {
    isMitIdLogin: PropTypes.bool,
    closeAction: PropTypes.func,
    loginHandler: PropTypes.func.isRequired,
    forgotPassHandler: PropTypes.func.isRequired,
    stayOnPrevPage: PropTypes.bool,
    theme: PropTypes.string,
    site: PropTypes.string.isRequired,
    oldAPI: PropTypes.bool,
    showResetPassword: PropTypes.bool,
}

export default Login
