import React from 'react'
import styled, { withTheme } from 'styled-components'
import { ButtonsArea } from '../../styled'

import Button from '../../../Button'

const CancelButton = styled(Button)`
    max-width: calc(50% - 8px);
`

const AcceptButton = styled(Button)`
    max-width: calc(50% - 8px);
    &:disabled {
        opacity: 0.5;
    }
`

const AcceptAndCancelButtons = ({
    theme,
    cancelAction = () => {},
    acceptAction = () => {},
    disableAcceptButton = false,
    cancelLabel = 'Annuller',
    acceptLabel = 'Vis',
    position = 'absolute',
    borderRadius,
}) => {
    return (
        <ButtonsArea position={position} borderRadius={borderRadius}>
            <CancelButton
                bgColor={theme.colors.colorBackgroundSurfacePrimaryDefault}
                borderColor={theme.colors.colorBorderPrimaryDefault}
                onClick={cancelAction}
            >
                {cancelLabel}
            </CancelButton>
            <AcceptButton onClick={acceptAction} disabled={disableAcceptButton}>
                {acceptLabel}
            </AcceptButton>
        </ButtonsArea>
    )
}

AcceptAndCancelButtons.propTypes = {}

export default withTheme(AcceptAndCancelButtons)
