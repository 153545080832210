import React from 'react'
import Icon from '../../../Icon'
import Text from '../../../Text'
import calendarIcon from '../../../../images/icons/calendar.svg'
import { withTheme } from 'styled-components'
import { APPLY_BUTTON_MAX_WIDTH, ButtonsArea } from '../../styled'
import Button from '../../../../components/Button'

const ButtonArea = ({
    theme,
    openChooseDate = () => {},
    showClearButton = false,
    clearChosenDate = () => {},
    chosenDate = '',
    buttonsAreaPosition = null,
}) => {
    return (
        <ButtonsArea
            position={
                buttonsAreaPosition !== null ? buttonsAreaPosition : 'absolute'
            }
            zIndex={13}
        >
            {showClearButton && (
                <Button
                    maxWidth={APPLY_BUTTON_MAX_WIDTH}
                    borderColor={theme.colors.colorBorderPrimaryDefault}
                    bgColor={theme.colors.colorBackgroundSurfacePrimaryDefault}
                    onClick={clearChosenDate}
                >
                    <Text
                        weight={theme.fontWeight}
                        color={theme.colors.colorTextDangerDefault}
                        align={'center'}
                    >
                        Nulstil
                    </Text>
                </Button>
            )}
            <Button
                maxWidth={APPLY_BUTTON_MAX_WIDTH}
                onClick={openChooseDate}
                bgColor={theme.colors.colorBackgroundFillAccentDefault}
            >
                <Icon
                    height={'16px'}
                    width={'16px'}
                    src={calendarIcon}
                    color={theme.colors.colorTextPrimaryDefault}
                />
                <Text
                    weight={theme.fontWeight}
                    color={theme.colors.colorTextPrimaryDefault}
                    align={'center'}
                >
                    {chosenDate.length && showClearButton > 0
                        ? chosenDate
                        : 'Vælg periode'}
                </Text>
            </Button>
        </ButtonsArea>
    )
}

ButtonsArea.propTypes = {}

export default withTheme(ButtonArea)
