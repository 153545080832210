import styled from 'styled-components'

export const boxFlex = styled.div`
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
`
