import { detectMobile } from '../../utils/deviceDetect'

export const BONUSES_TYPE_BY_PRODUCT = {
    heste: 'D',
    sport: 'D',
    casino: 'W',
    casinogo: 'W',

}

export const getMaxWidthByProduct = (product) => {
    return detectMobile()
        ? '100%'
        : product === 'sport' || product === 'heste'
            ? '330px'
            : '100%'
}
export const getPaddingByProduct = (product) => {
    return detectMobile() || (product !== 'sport' && product !== 'heste')
        ? '20px 16px'
        : '24px 0 12px'
}
export const getPaddingsForButtonsByProduct = () => {
    return detectMobile() ? '16px 23px 50px' : '16px 15px'
}

export const exportNumberFromStringWithoutComma = (string) => {
    return string.includes(',') ? Number(string.replace(',', '.')) : Number(string)
}

