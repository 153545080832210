import React from 'react'
import { InfoBoxWrapper, InfoListNumered } from './styled'
import Text from '../../Text'
import { PaymentTexts } from '../../../texts/PaymentTexts'
import { detectMobile } from '../../../utils/deviceDetect'

const InfoYourData = ({ theme, type = 'description' }) => {
    return (
        <InfoBoxWrapper
            type={type}
            theme={theme}
            margin={'24px 0 0 '}
        >
            <Text
                size={13}
                lineHeight={'20px'}
                margin={'0'}
                bold
                color={theme.colors.black_font_900}
            >
                {PaymentTexts.verifyYourAccount.title}
            </Text>
            <Text
                size={10}
                lineHeight={'16px'}
                margin={'8px 0'}
                color={theme.colors.black_font_900}
            >
                {PaymentTexts.verifyYourAccount.text1}
            </Text>
            <InfoListNumered>
                <li>
                    {PaymentTexts.verifyYourAccount.list.text1.bold}
                    <span className={'regular'}>{' '}{PaymentTexts.verifyYourAccount.list.text1.regular}</span>
                </li>
                <li>
                    {PaymentTexts.verifyYourAccount.list.text2.bold}{' '}
                    <a
                        href="mailto:dokument@bet25.dk"
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                        className={'pl-5 bold'}
                    >
                        {PaymentTexts.verifyYourAccount.list.text2.email}
                    </a>
                </li>
            </InfoListNumered>
            <Text
                size={10}
                lineHeight={'16px'}
                margin={'8px 0 0 0 '}
                color={theme.colors.black_font_900}
            >
                {PaymentTexts.verifyYourAccount.text2}
            </Text>
        </InfoBoxWrapper>
    )
}

export default InfoYourData
