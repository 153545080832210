import React from 'react'
import CloseIcon from './../../images/icons/close-thin.svg'
import { CloseButtonIconWrapper } from './styled'
import Icon from '../Icon'

const CloseButtonIcon = ({click = () => {}, top}) => {
    return (
        <CloseButtonIconWrapper onClick={click} top={top}>
            <Icon
                height={'20px'}
                width={'20px'}
                src={CloseIcon}
                color={'#F5F8FC'}
            />
        </CloseButtonIconWrapper>
    )
}

export default CloseButtonIcon
