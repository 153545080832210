import React from 'react'
import { InfoBoxWrapper, InfoList, InfoWrapperIconWithText } from './styled'
import Text from '../../Text'
import { PaymentTexts } from '../../../texts/PaymentTexts'
import Icon from '../../Icon'
import NemKontoIcon from './../../../images/icons/nemkonto_logo.svg'
import InfoIcon from '../../../images/icons/info_new.svg'
import { detectMobile } from '../../../utils/deviceDetect'

const InfoPayment = ({ theme, type = 'description',  }) => {
    return (
        <InfoBoxWrapper type={type} theme={theme} margin={'32px 0 0' }>
            <InfoWrapperIconWithText>
                <Icon
                    color={theme.info.icon_content_300}
                    height={'18px'}
                    width={'18px'}
                    src={InfoIcon}
                />
                <Text
                    size={13}
                    lineHeight={'20px'}
                    weight
                    margin={'0 0 0 8px'}
                    color={theme.texts.default.primary}
                >
                    {PaymentTexts.paymentInformation.title}
                </Text>
            </InfoWrapperIconWithText>
            <InfoList>
                <li>
                    {PaymentTexts.paymentInformation.minAmount}{' '}
                    <b className={'pl-5'}>{PaymentTexts.paymentInformation.minAmountValue}</b>
                </li>
                <li>
                    {PaymentTexts.paymentInformation.fee}{' '}
                    <b className={'pl-5'}>{PaymentTexts.paymentInformation.feeValue}</b>
                </li>
                <li>
                    {PaymentTexts.paymentInformation.processingTime}{' '}
                    <b className={'pl-5'}>
                        {PaymentTexts.paymentInformation.processingTimeValue}
                        <a
                            href="https://intercom.help/25syv/da/articles/2188686-information-om-udbetalinger"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                            className={'pl-5'}>
                            {PaymentTexts.readMore}
                        </a>
                    </b>
                </li>
                <li>
                    {PaymentTexts.paymentInformation.paymentMethods}{' '}
                    <img src={NemKontoIcon} alt="nem konto" />
                    <b className={'pl-5'}>
                        <a
                            href="https://intercom.help/25syv/da/articles/2188686-information-om-udbetalinger"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            {PaymentTexts.readMore}
                        </a>
                    </b>
                </li>
            </InfoList>
        </InfoBoxWrapper>
    )
}

export default InfoPayment
