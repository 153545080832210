import styled from 'styled-components'
import { detectMobile } from '../../utils/deviceDetect'

export const APPLY_BUTTON_MAX_WIDTH = '320px'

export const ButtonsArea = styled.div`
    width: 100%;
    flex-direction: row;
    bottom: 0;
    align-items: center;
    justify-content: center;
    margin: 0;
    box-sizing: border-box;
    background: ${(props) => props.theme.colors.colorBackgroundDefault};
    display: flex;
    gap: 16px;
    padding: ${detectMobile() ? '16px ' : '16px 40px'};
    border-top: 1px solid
        ${(props) => props.theme.colors.colorBackgroundSurfaceTertiaryDefault};
    position: ${({ position }) => position || 'absolute'};
    border-radius: ${({ borderRadius }) => borderRadius || '0'};
    z-index: ${({ zIndex }) => zIndex || 2};
    left: 0;
`
