
export const PaymentTexts = {
    readMore: 'Læs mere',
    paymentInformation: {
        title: 'Udbetalings information',
        minAmount: 'Min. udbetaling:',
        minAmountValue: '100 kr.',
        fee: 'Udbetalingsgebyr:',
        feeValue: '0 kr.',
        processingTime: 'Behandlingstid:',
        processingTimeValue: '1-2 bankdage',
        paymentMethods: 'Udbetales til: ',
    },
    paymentNotPossible: {
        title: 'Udbetaling er ikke mulig',
        text: 'da du har en eller flere aktive bonusser. Du skal først gennemspille din bonus før du kan foretage en udbetaling.'
    },
    verifyYourAccount: {
        title: 'Verificer din konto, før du kan udbetale.',
        text1: 'Vi beder dig om at verificere dine oplysninger, før du kan gennemføre udbetalingen. Det er nemt og hurtigt.',
        list: {
            text1: {
                bold: 'Tag et billede af dit sundhedskort',
                regular: '(fysisk kort eller via Sundhedskortet-appen).'
            },
            text2: {
                bold: 'Send det til os på',
                email: 'dokument@bet25.dk.'
            }
        },
        text2: 'Så snart vi har modtaget dine oplysninger og godkendt dem, vil du have mulighed for at kunne udbetale.'
    },
    remember: {
        title: 'Husk:',
        reg1: 'send dokumentationen',
        bold: 'inden for 30 dage',
        reg2: 'fra kontoen er oprettet for at holde din konto aktiv'
    }

}
