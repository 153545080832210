import React from 'react'
import Icon from '../../Icon'
import {
    ButtonsArea,
    ButtonWrapper,
    Divider,
    MitIdButton,
    TextWrapper,
} from '../styled'
import Text from '../../Text'
import { LOGIN_TYPES } from '../../../services/PunterService'
import mitIdLogo from '../../../images/icons/mitId.svg'
import Spinner from '../../Spinner'
import PropTypes from 'prop-types'

const ButtonArea = ({
    title = '',
    currentTheme = {},
    btnLoading = {},
    buttonClickHandler = {},
}) => {
    return (
        <ButtonsArea bg={currentTheme.colors.bg}>
            <TextWrapper>
                <Divider color={currentTheme.colors.divider} />

                {title && (
                    <Text
                        padding={'0 20px'}
                        color={currentTheme.colors.primaryAction || '#9198AA'}
                        condensed={currentTheme.condensed}
                    >
                        {title}
                    </Text>
                )}
                <Divider color={currentTheme.colors.divider} />
            </TextWrapper>
            <ButtonWrapper>
                <MitIdButton
                    color="#fff"
                    hide={true}
                    show={btnLoading[LOGIN_TYPES.MIT_ID]}
                    onClick={() => buttonClickHandler(LOGIN_TYPES.MIT_ID)}
                >
                    <Icon
                        height={'14px'}
                        width={'54px'}
                        src={mitIdLogo}
                        color={'white'}
                    />
                    <Text
                        color={'white'}
                        bold={currentTheme.bold}
                        condensed={currentTheme.condensed}
                        align={'center'}
                    >
                        Log ind med MitID
                    </Text>
                    {btnLoading[LOGIN_TYPES.MIT_ID] && (
                        <Spinner color={'#fff'} size={'md'} />
                    )}
                </MitIdButton>
            </ButtonWrapper>
        </ButtonsArea>
    )
}

ButtonsArea.propTypes = {
    title: PropTypes.string,
    currentTheme: PropTypes.object,
    btnLoading: PropTypes.func,
    buttonClickHandler: PropTypes.func,
}
export default ButtonArea
