export const defaultThemeGenerated = {
  "colorBackgroundFillPrimaryDefault": "var(--color-slate-50)",
  "colorBackgroundFillSuccesDefault": "var(--color-green-700)",
  "colorBackgroundUtilitiesOverlayPrimary": "var(--color-black-800)",
  "colorTextUtilitiesLink": "var(--color-blue-700)",
  "colorBackgroundFillSuccesActive": "var(--color-green-500)",
  "colorBackgroundFillSuccesHover": "var(--color-green-600)",
  "colorBackgroundFillWarningActive": "var(--color-orange-500)",
  "colorBackgroundFillWarningHover": "var(--color-orange-600)",
  "colorBackgroundFillWarningDefault": "var(--color-orange-700)",
  "colorBackgroundFillDangerActive": "var(--color-red-500)",
  "colorBackgroundFillDangerHover": "var(--color-red-600)",
  "colorBackgroundFillDangerDefault": "var(--color-red-700)",
  "colorTextPrimaryDefault": "var(--color-slate-950)",
  "colorTextUtilitiesOnOverlay": "var(--color-white-1000)",
  "colorTextSuccessPrimaryDefault": "var(--color-green-800)",
  "colorTextDangerDefault": "var(--color-red-800)",
  "colorTextWarningDefault": "var(--color-orange-800)",
  "colorBorderPrimaryDefault": "var(--color-slate-300)",
  "colorBorderSuccessDefault": "var(--color-green-700)",
  "colorBorderWarningDefault": "var(--color-orange-400)",
  "colorBorderDangerDefault": "var(--color-red-400)",
  "colorIconPrimaryDefault": "var(--color-slate-900)",
  "colorIconBrandDefault": "var(--color-brand-casino-700)",
  "colorIconSuccesDefault": "var(--color-green-900)",
  "colorIconWarningDefault": "var(--color-orange-700)",
  "colorIconDangerDefault": "var(--color-red-700)",
  "colorBackgroundUtilitiesOverlaySeconday": "var(--color-black-500)",
  "colorBackgroundUtilitiesOverlayTertiary": "var(--color-black-200)",
  "colorTextAccentPrimary": "var(--color-yellow-700)",
  "colorTextUtilitiesSeperator": "var(--color-slate-300)",
  "colorIconAccentDefault": "var(--color-yellow-700)",
  "colorIconAccentDisabled": "var(--color-yellow-200)",
  "colorShadowDefault50": "var(--color-black-50)",
  "colorShadowDefault100": "var(--color-black-100)",
  "colorShadowDefault400": "var(--color-black-400)",
  "colorBackgroundFillInfoDefault": "var(--color-blue-700)",
  "colorBackgroundFillInfoHover": "var(--color-blue-600)",
  "colorBackgroundFillInfoActive": "var(--color-blue-500)",
  "colorTextInfoDefault": "var(--color-blue-800)",
  "colorBackgroundSurfaceInverseDefault": "var(--color-slate-950)",
  "colorBackgroundSurfacePrimaryDefault": "var(--color-white-1000)",
  "colorBackgroundSurfaceSecondaryDefault": "var(--color-slate-200)",
  "colorBackgroundSurfaceSecondaryHover": "var(--color-black-50)",
  "colorBackgroundSurfacePrimaryHover": "var(--color-black-50)",
  "colorBackgroundSurfaceInverseHover": "var(--color-white-50)",
  "colorBackgroundSurfaceBrandPrimaryDefault": "var(--color-brand-casino-700)",
  "colorBackgroundSurfaceBrandPrimaryHover": "var(--color-white-100)",
  "colorBackgroundSurfaceOnBrandPrimaryDefault": "var(--color-brand-casino-600)",
  "colorBackgroundSurfaceOnBrandPrimaryHover": "var(--color-white-100)",
  "colorBackgroundDefault": "var(--color-slate-100)",
  "colorBackgroundFillPrimaryHover": "var(--color-black-50)",
  "colorBackgroundFillSecondaryDefault": "var(--color-slate-100)",
  "colorBackgroundFillSecondaryHover": "var(--color-black-50)",
  "colorBackgroundFillInverseDefault": "var(--color-slate-950)",
  "colorBackgroundFillInverseHover": "var(--color-white-50)",
  "colorBackgroundFillAccentDefault": "var(--color-yellow-700)",
  "colorBackgroundFillAccentHover": "var(--color-white-300)",
  "colorBackgroundSurfaceInfoPrimaryActive": "var(--color-blue-300)",
  "colorBackgroundSurfaceInfoDefault": "var(--color-blue-100)",
  "colorBackgroundSurfaceInfoHover": "var(--color-blue-200)",
  "colorBackgroundSurfaceSuccesActive": "var(--color-green-300)",
  "colorBackgroundSurfaceSuccesDefault": "var(--color-green-100)",
  "colorBackgroundSurfaceSuccesHover": "var(--color-green-200)",
  "colorBackgroundSurfaceWarningSecondary": "var(--color-orange-200)",
  "colorBackgroundSurfaceWarningDefault": "var(--color-orange-50)",
  "colorBackgroundSurfaceWarningHover": "var(--color-orange-100)",
  "colorBackgroundSurfaceDangerActive": "var(--color-red-300)",
  "colorBackgroundSurfaceDangerDefault": "var(--color-red-100)",
  "colorBackgroundSurfaceDangerHover": "var(--color-red-200)",
  "colorProductsJackpotUltra": "var(--color-red-700)",
  "colorProductsJackpotSuper": "var(--color-red-600)",
  "colorProductsJackpotWeekly": "var(--color-red-500)",
  "colorProductsVgamesV4": "var(--color-red-700)",
  "colorProductsVgamesV5": "var(--color-red-600)",
  "colorProductsVgamesV64": "var(--color-red-500)",
  "colorProductsVgamesV65": "var(--color-red-700)",
  "colorProductsVgamesV75": "var(--color-red-600)",
  "colorProductsVgamesGs75": "var(--color-red-500)",
  "colorProductsVgamesV85": "var(--color-red-500)",
  "colorProductsVgamesSimples": "var(--color-red-700)",
  "colorProductsJackpotDaily": "var(--color-red-500)",
  "colorProductsSportBig9": "var(--color-red-700)",
  "colorProductsSportDaily5": "var(--color-red-700)",
  "colorTextSecondaryDefault": "var(--color-slate-700)",
  "colorTextPrimaryDisabled": "var(--color-slate-400)",
  "colorTextOnBrandPrimaryDefault": "var(--color-white-1000)",
  "colorTextOnAccentDefault": "var(--color-slate-950)",
  "colorTextSecondaryDisabled": "var(--color-slate-400)",
  "colorTextInverseDefault": "var(--color-white-1000)",
  "colorTextTertiaryDefault": "var(--color-slate-600)",
  "colorTextTertiaryDisabled": "var(--color-slate-400)",
  "colorTextSecondarySecondary": "var(--color-slate-500)",
  "colorBorderPrimaryActive": "var(--color-slate-700)",
  "colorBorderInfoDefault": "var(--color-blue-400)",
  "colorIconSecondaryDefault": "var(--color-slate-500)",
  "colorIconTertiaryDefault": "var(--color-slate-400)",
  "colorTextOnBrandDefault": "var(--color-brand-casino-50)",
  "colorTextOnBrandHover": "var(--color-brand-casino-100)",
  "colorTextOnBrandActive": "var(--color-brand-casino-200)",
  "colorIconInverseDefault": "var(--color-white-1000)",
  "colorIconInfoDefault": "var(--color-blue-700)",
  "colorBackgroundSurfaceTertiaryDefault": "var(--color-slate-300)",
  "colorBackgroundSurfaceTertiaryHover": "var(--color-black-50)",
  "colorTextAccentDefault": "var(--color-yellow-800)",
  "colorTextAccentDisabled": "var(--color-grey-300)",
  "colorBackgroundSurfaceBrandSecondaryDefault": "var(--color-brand-casino-800)",
  "colorBackgroundSurfaceBrandSecondaryHover": "var(--color-white-50)",
  "colorBackgroundSurfaceBrandTertiaryDefault": "var(--color-brand-casino-900)",
  "colorBackgroundSurfaceBrandTertiaryHover": "var(--color-white-50)",
  "colorBackgroundSurfaceOnBrandSecondaryDefault": "var(--color-brand-casino-500)",
  "colorBackgroundSurfaceOnBrandSecondaryHover": "var(--color-white-100)",
  "colorBackgroundSurfaceOnBrandTertiaryDefault": "var(--color-brand-casino-400)",
  "colorBackgroundSurfaceOnBrandTertiaryHover": "var(--color-white-100)",
  "colorTextBrandTertiaryDefault": "var(--color-brand-casino-900)",
  "colorTextBrandTertiaryHover": "var(--color-white-50)",
  "colorBackgroundFillOnBrandPrimaryDefault": "var(--color-brand-casino-600)",
  "colorBackgroundFillOnBrandPrimaryHover": "var(--color-white-100)",
  "colorBackgroundFillOnBrandSecondaryDefault": "var(--color-brand-casino-500)",
  "colorBackgroundFillOnBrandSecondaryHover": "var(--color-white-100)",
  "colorBackgroundFillOnBrandTertiaryDefault": "var(--color-brand-casino-400)",
  "colorBackgroundFillOnBrandTertiaryHover": "var(--color-white-100)",
  "colorBackgroundFillBrandPrimaryDefault": "var(--color-brand-casino-700)",
  "colorBackgroundFillBrandPrimaryHover": "var(--color-white-100)",
  "colorBackgroundFillBrandSecondaryDefault": "var(--color-brand-casino-800)",
  "colorBackgroundFillBrandSecondaryHover": "var(--color-white-50)",
  "colorBackgroundFillBrandTertiaryDefault": "var(--color-brand-casino-900)",
  "colorBackgroundFillBrandTertiaryHover": "var(--color-white-50)",
  "colorTextBrandPrimaryDefault": "var(--color-brand-casino-700)",
  "colorTextBrandPrimaryHover": "var(--color-white-100)",
  "colorTextBrandSecondaryDefault": "var(--color-brand-casino-800)",
  "colorTextBrandSecondaryHover": "var(--color-white-50)",
  "colorTextOnBrandSecondaryDefault": "var(--color-brand-casino-200)",
  "colorTextOnBrandTertiaryDefault": "var(--color-brand-casino-400)",
  "colorBorderPrimaryHover": "var(--color-slate-500)",
  "colorBorderSecondaryDefault": "var(--color-slate-500)",
  "colorBorderSecondaryHover": "var(--color-slate-600)",
  "colorBorderSecondaryActive": "var(--color-slate-700)",
  "colorTextSuccessSecondaryDefault": "var(--color-green-500)",
  "colorBackgroundFillAccentInactive": "var(--color-yellow-200)",
  "colorTextOnAccentInactive": "var(--color-grey-300)",
  "colorBackgroundFillAccentPressed": "var(--color-black-100)",
  "colorBackgroundFillSecondaryPressed": "var(--color-black-100)",
  "colorBackgroundFillSecondaryInactive": "var(--color-slate-50)",
  "colorBackgroundFillBrandPrimaryInactive": "var(--color-brand-casino-100)",
  "colorBackgroundFillBrandPrimaryPressed": "var(--color-black-100)",
  "colorTextBrandPrimaryDisabled": "var(--color-grey-300)",
  "colorIconPrimaryDisabled": "var(--color-grey-300)"
};
