import React, { useState } from 'react'
import Flex from '../../../Flex'
import Text from '../../../Text'
import DatePicker from './../../../DatePicker'
import { detectMobile } from '../../../../utils/deviceDetect'
import IconCalendar from '../../../../images/icons/calendar.svg'
import IconClose from '../../../../images/icons/close-thin.svg'
import Icon from '../../../Icon'
import {
    ChooseDateWrapper,
    DatePickerWrapper,
    DateRangeInput,
    DateRangeItem,
    DateRangeWrapper,
    HR,
    Wrapper,
    WrapperCloseIcon,
} from '../styled'
import AcceptAndCancelButtons from './AcceptAndCancelButtons'
import { ThemeProvider } from 'styled-components'
import { themesMap } from '../../../../theme'
import moment from 'moment'

const ChooseDate = ({
    closeChooseDate = () => {},
    datePickerTheme = {},
    currentTheme = 'default',
    sendChosenDate = () => {},
}) => {
    const currentDate = moment().format('DD.MM.YYYY')
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [selectedMode, setSelectedMode] = useState('')
    const theme = themesMap[currentTheme]
    const [disableShowButton, setDisableShowButton] = useState(true)
    const [dateFrom, setDateFrom] = useState('dd.mm.åååå')
    const [dateTo, setDateTo] = useState(currentDate)

    const formatDateToRequest = (date) => {
        return moment(date, 'DD.MM.YYYY').format('DDMMYYYY')
    }

    const openDatePicker = (mode) => {
        if (mode === 'dateFrom') {
            setSelectedMode('dateFrom')
        } else {
            setSelectedMode('dateTo')
        }
        setShowDatePicker(true)
    }

    const onDateSelected = (mode, date) => {
        let dateFormatted = moment(date).format('DD.MM.YYYY')
        if (mode === 'dateFrom') {
            setDateFrom(dateFormatted)
            setDisableShowButton(moment(dateFormatted).isAfter(dateTo))
        } else {
            setDateTo(dateFormatted)
            setDisableShowButton(moment(dateFormatted).isBefore(dateFrom))
        }
        setShowDatePicker(false)
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrapper
                height={'100%'}
                justify={detectMobile() ? 'flex-end' : 'center'}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <ChooseDateWrapper>
                    {showDatePicker ? (
                        <DatePickerWrapper
                            bgColor={theme.colors.colorBackgroundDefault}
                        >
                            <DatePicker
                                maxDate={new Date()}
                                onDateSelected={(date) => {
                                    onDateSelected(selectedMode, date)
                                    setShowDatePicker(false)
                                }}
                                theme={theme}
                                datePickerTheme={datePickerTheme}
                                closeDatepicker={() => setShowDatePicker(false)}
                            />
                        </DatePickerWrapper>
                    ) : (
                        <Flex
                            justify={'flex-start'}
                            align={'center'}
                            direction={'column'}
                            height={detectMobile() ? '310px' : '297px'}
                            width={detectMobile() ? '100%' : '520px'}
                            position={'relative'}
                            borderRadius={'8px'}
                            bgColor={theme.colors.colorBackgroundDefault}
                        >
                            {detectMobile() && (
                                <WrapperCloseIcon onClick={closeChooseDate}>
                                    <Icon
                                        height={'24px'}
                                        width={'24px'}
                                        src={IconClose}
                                        color={
                                            theme.colors
                                                .colorIconSecondaryDefault
                                        }
                                    />
                                </WrapperCloseIcon>
                            )}
                            <Text
                                margin={'0'}
                                size={18}
                                bold
                                boxSizing={'border-box'}
                                maxWidth={'100%'}
                                padding={'16px'}
                                width={'100%'}
                                align={'center'}
                                color={theme.colors.colorTextPrimaryDefault}
                            >
                                Vælg periode
                            </Text>
                            <HR
                                type={'solid'}
                                height={'1px'}
                                color={
                                    theme.colors
                                        .colorBackgroundSurfaceTertiaryDefault
                                }
                            />

                            <DateRangeWrapper margin={'0'}>
                                <DateRangeItem>
                                    <Text
                                        size={13}
                                        margin={'0'}
                                        padding={'0 0 0 13px'}
                                        color={theme.colors.basic}
                                        boxSizing={'border-box'}
                                        maxWidth={'100%'}
                                    >
                                        Vælg startdato
                                    </Text>
                                    <DateRangeInput
                                        borderColor={
                                            theme.colors
                                                .colorBackgroundSurfaceTertiaryDefault
                                        }
                                        bgColor={
                                            theme.colors
                                                .colorBackgroundSurfacePrimaryDefault
                                        }
                                        onClick={() =>
                                            openDatePicker('dateFrom')
                                        }
                                    >
                                        <Text
                                            size={16}
                                            width={'100%'}
                                            margin={'0'}
                                            color={
                                                dateFrom === 'dd.mm.åååå'
                                                    ? theme.colors
                                                          .colorTextTertiaryDefault
                                                    : theme.colors.basic
                                            }
                                            boxSizing={'border-box'}
                                            maxWidth={'100%'}
                                        >
                                            {dateFrom}
                                        </Text>
                                        <Icon
                                            height={'17px'}
                                            width={'20px'}
                                            ml={1}
                                            src={IconCalendar}
                                            color={
                                                theme.colors
                                                    .colorTextPrimaryDefault
                                            }
                                            alt={'calendar'}
                                        />
                                    </DateRangeInput>
                                </DateRangeItem>
                                {!detectMobile() && (
                                    <Text
                                        width={'auto'}
                                        margin={'0'}
                                        padding={'18px 16px 0'}
                                    >
                                        -
                                    </Text>
                                )}
                                <DateRangeItem>
                                    <Text
                                        size={13}
                                        margin={'0'}
                                        padding={'0 0 0 13px'}
                                        color={theme.colors.basic}
                                        boxSizing={'border-box'}
                                        maxWidth={'100%'}
                                    >
                                        Vælg slutdato
                                    </Text>
                                    <DateRangeInput
                                        borderColor={
                                            theme.colors
                                                .colorBackgroundSurfaceTertiaryDefault
                                        }
                                        bgColor={
                                            theme.colors
                                                .colorBackgroundSurfacePrimaryDefault
                                        }
                                        onClick={() => openDatePicker('dateTo')}
                                    >
                                        <Text
                                            width={'100%'}
                                            margin={'0'}
                                            color={
                                                dateTo === 'dd.mm.åååå'
                                                    ? theme.colors
                                                          .colorTextTertiaryDefault
                                                    : theme.colors.basic
                                            }
                                            boxSizing={'border-box'}
                                            maxWidth={'100%'}
                                            size={16}
                                        >
                                            {dateTo}
                                        </Text>
                                        <Icon
                                            height={'17px'}
                                            ml={1}
                                            width={'20px'}
                                            src={IconCalendar}
                                            color={
                                                theme.colors
                                                    .colorTextPrimaryDefault
                                            }
                                            alt={'calendar'}
                                        />
                                    </DateRangeInput>
                                </DateRangeItem>
                            </DateRangeWrapper>
                            <AcceptAndCancelButtons
                                position={'relative'}
                                cancelAction={closeChooseDate}
                                disableAcceptButton={
                                    dateFrom === 'dd.mm.åååå' ||
                                    disableShowButton
                                }
                                acceptAction={() => {
                                    sendChosenDate({
                                        dateFrom: formatDateToRequest(dateFrom),
                                        dateTo: formatDateToRequest(dateTo),
                                    })
                                }}
                                borderRadius={
                                    detectMobile() ? '0' : '0 0 8px 8px'
                                }
                            />
                        </Flex>
                    )}
                </ChooseDateWrapper>
            </Wrapper>
        </ThemeProvider>
    )
}

ChooseDate.propTypes = {}

export default ChooseDate
