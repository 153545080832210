import React, { useEffect, useState } from 'react'
import InfiniteCalendar from 'react-infinite-calendar'
import 'react-infinite-calendar/styles.css'
import moment from 'moment'
import daLocale from 'date-fns/locale/da'
import AcceptAndCancelButtons from '../UserArea/UserHistory/Components/AcceptAndCancelButtons'
import {
    DatePickerWrapper,
    DatePickerPage,
    Wrapper,
} from '../UserArea/UserHistory/styled'
import { detectMobile } from '../../utils/deviceDetect'

const DatePicker = ({
    theme,
    onDateSelected = () => {},
    propsDate,
    selectedDate,
    minDateProps,
    maxDateProps,
    datePickerTheme = {},
    closeDatepicker = () => {},
}) => {
    const [date, setDate] = useState(new Date())
    const defaultMinDate = new Date(1980, 0, 1) // default infinite scroll value
    const defaultMaxDate = new Date(2030, 11, 31)
    const [minDate, setMinDate] = useState(minDateProps || defaultMinDate)
    const [maxDate, setMaxDate] = useState(maxDateProps || defaultMaxDate)

    const chooseDate = () => {
        onDateSelected(date)
    }

    const onSelectHandler = (date) => {
        setDate(date)
    }

    useEffect(() => {
        if (selectedDate) {
            setDate(propsDate)
        } else {
            setDate(propsDate)
        }
    }, [])

    const rangeConfigs = !selectedDate
        ? {
              minDate: minDate ? minDate : new Date(moment().format()),

              maxDate: maxDate
                  ? maxDate
                  : new Date(
                        moment()
                            .add(6, 'days')
                            .format()
                    ),
          }
        : {
              minDate: minDate ? minDate : defaultMinDate,
              maxDate: maxDate ? maxDate : defaultMaxDate,
          }

    return (
        <DatePickerPage>
            <Wrapper overflowY={'hidden'} padding={'0'}>
                <DatePickerWrapper>
                    <InfiniteCalendar
                        {...rangeConfigs}
                        selected={date}
                        onSelect={onSelectHandler}
                        theme={datePickerTheme || DATEPICKER_THEME_CONFIG}
                        width={detectMobile() ? window.innerWidth : 516}
                        height={detectMobile() ? 307 : 418}
                        rowHeight={70}
                        locale={{
                            locale: daLocale,
                            blank: 'Vælg en dato ...',
                            headerFormat: 'ddd, MMM DD',
                            todayLabel: {
                                long: 'I dag',
                            },
                            months: 'januar, februar, marts, april, maj, juni, juli, august, september, oktober, november, december'.split(
                                ','
                            ),
                            monthsShort: 'jan,feb,mar2,apr2,maj,jun,jul,aug,sep,okt,nov,dec'.split(
                                ','
                            ),
                            weekdays: [
                                'Søn',
                                'Man',
                                'Tir',
                                'Ons',
                                'Tor',
                                'Fre',
                                'Lør',
                            ],
                            weekStartsOn: 0,
                        }}
                    />
                </DatePickerWrapper>
            </Wrapper>
            <AcceptAndCancelButtons
                cancelAction={() => {
                    closeDatepicker()
                }}
                position={'relative'}
                acceptAction={chooseDate}
                acceptLabel={'Vælg dato'}
                disableAcceptButton={moment(new Date()).isBefore(date)}
            />
        </DatePickerPage>
    )
}

export default DatePicker
