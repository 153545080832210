import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import Wallet from './Wallet'
import { detectMobile } from '../../utils/deviceDetect'
import {
    ButtonTransferMoney,
    WithdrawBonusesWrapper,
    WithdrawButtonsArea,
    WithdrawContent,
    WithdrawContentWrapper,
    WithdrawWrapper,
} from './styled'
import InfoPayment from './Info/InfoPayment'
import { themesMap } from '../../theme'
import InfoPaymentNotPossible from './Info/InfoPaymentNotPossible'
import InfoVerifyYourData from './Info/InfoVerifyYourData'
import InfoSendDocs from './Info/InfoSendDocs'
import ActiveBonuses from './ActiveBonuses'
import Text from '../Text'
import ValidatingInput from '../ValidInput/ValidatingInputnNew'
import Spinner from '../Spinner'
import {
    BONUSES_TYPE_BY_PRODUCT,
    exportNumberFromStringWithoutComma,
    getMaxWidthByProduct,
    getPaddingByProduct,
    getPaddingsForButtonsByProduct,
} from './helper'
import PropTypes from 'prop-types'

const Withdraw = ({
    product = 'sport',
    currentTheme = 'default',
    userData = {},
    bonusesData = [],
    onRemove = (amount) => {},
    transferMoneyHandler = (amount) => {},
    redirectUrl = () => {},
    payoutPending,
}) => {
    const [bonusesWithZeroAmount, setBonusesWithZeroAmount] = React.useState([])
    const [moneyAmount, setMoneyAmount] = React.useState('')
    const [bonusAmount, setBonusAmount] = React.useState(0)
    const theme = themesMap[currentTheme]
    const [error, setError] = React.useState(false)

    useEffect(() => {
        let bonusAmount = 0
        let zeroAmountBonuses = []
        bonusesData.forEach((item) => {
            if (
                (product === 'sport' || product === 'heste') &&
                item.bonusType === 'D'
            ) {
                bonusAmount = item.amount
            }
            if ( item.bonusType === 'W' && (product === 'casino' || product === 'casinogo')) {
                bonusAmount = item.amount
            }
            if (item.amount === 0 ) {
                zeroAmountBonuses.push(true)
            }
        })
        setBonusesWithZeroAmount(zeroAmountBonuses)
        setBonusAmount(bonusAmount)
    }, [bonusesData])

    useEffect(() => {
        return () => {
            setBonusesWithZeroAmount([])
            setMoneyAmount('')
            setError(false)
        }
    }, [])

    const getInfoBox = (theme, status, bonusesData) => {
        if (userData.status === 'temp') {
            return (
                <div>
                    <InfoVerifyYourData theme={theme} type={'rec'} />
                    <InfoSendDocs theme={theme} type={'info'} />
                </div>
            )
        } else if (bonusesData.length === 0 || bonusesData.length === bonusesWithZeroAmount.length) {
            return <InfoPayment theme={theme} />
        } else if (bonusesData.length > 0 &&  bonusesData.length !== bonusesWithZeroAmount.length) {
            return <InfoPaymentNotPossible theme={theme} type={'info'} />
        }
    }

    const validateAmountToTransfer = (moneyAmount, userData, bonusesData) => {
        const {balance, status } = userData
        let amount =   exportNumberFromStringWithoutComma(moneyAmount)
        const regex = /^\d+[.,]$/;

        return (bonusesData.length > 0 && bonusesData.length !== bonusesWithZeroAmount.length) ||
            regex.test(moneyAmount) ||
            moneyAmount[0] === ',' ||
            moneyAmount[0] === '.' ||
            amount > balance ||
            amount < 100 ||
            balance < 100 ||
            status === 'temp';
    }

    const getActiveBonus = (product, bonuses, theme) => {
        let bonusesData = [];

        bonuses.forEach((item) => {
            item.bonusType === BONUSES_TYPE_BY_PRODUCT[product]
                ? bonusesData.unshift(item)
                : bonusesData.push(item);
        });

        return bonusesData.map((item) => {
            if (item.amount > 0) {

                return (
                    <ActiveBonuses
                        onRemove={onRemove}
                        activeBonus={item.amount}
                        initialRollover={item.initRollOver}
                        rollover={item.rollOver}
                        key={`item_${item.bonusType}`}
                        theme={theme}
                        expirationDate={item.expire}
                        type={item.bonusType}
                        minOdds={item.minOdds}
                        redirectUrl={redirectUrl}
                        bonusButtonText={'Gå til Bet25 Casino'}
                    />
                )
            } else {
                return null
            }
        })
    }

    const amountChangeHandler = (value) => {
        const regex = /^(?!0\d)\d*([.,]\d{0,2})?$/;
        if (regex.test(value)) {
            setMoneyAmount(value);
        }
        if (value === '') {
            setMoneyAmount(value);
        }
    };

    const checkValue = (error) => {
        setError(error.value)
    }

    return (
        <ThemeProvider theme={theme}>
            <WithdrawWrapper>
                <WithdrawContentWrapper>
                    <WithdrawContent
                        maxWidth={getMaxWidthByProduct(product)}
                        padding={getPaddingByProduct(product)}
                    >
                        <Wallet
                            theme={theme}
                            userData={userData}
                            bonus={bonusAmount}
                        />
                        { (bonusesData.length === 0 ||
                            bonusesData.length === bonusesWithZeroAmount.length
                            ) && userData.status !=='temp' && (
                            <div>
                                <Text
                                    size={13}
                                    lineHeight={'20px'}
                                    margin={detectMobile() ? '28px 0 0' :'24px 0 0'}
                                    color={theme.neutral.black_font_900}
                                    bold
                                >
                                    Indtast beløb
                                </Text>
                                <ValidatingInput
                                    allowFirstZero={false}
                                    disableErrorBottomMargin
                                    bottomLabelMargin={'10px 0 0 8px'}
                                    type="text"
                                    validate="minimum-kr, withdraw-max-amount, balance"
                                    value={moneyAmount}
                                    maxNumber={1000000}
                                    minNumber={100}
                                    balance={userData.balance}
                                    name="amount"
                                    onCheckValue={checkValue}
                                    onChangeHandler={amountChangeHandler}
                                    validateOnChange
                                    theme={'default'}
                                    altLabel="kr."
                                    bottomLabel={'Minimum 100 kr.'}
                                    styles={{
                                        InputWrapper: { margin: '0'},
                                    }}
                                />
                            </div>
                        )}
                        {getInfoBox(theme, userData.status, bonusesData)}

                        {(bonusesData.length > 0 &&
                            bonusesData.length !== bonusesWithZeroAmount.length
                        )  && userData.status !=='temp' && (
                            <WithdrawBonusesWrapper>
                                <Text
                                    bold
                                    size={13}
                                    lineHeight={'20px'}
                                    margin={'0'}
                                    padding={'0'}
                                    color={theme.neutral.black_font_900}
                                >
                                    Aktive bonusser
                                </Text>

                                {getActiveBonus(product, bonusesData, theme)}
                            </WithdrawBonusesWrapper>
                        )}
                    </WithdrawContent>
                </WithdrawContentWrapper>

                <WithdrawButtonsArea
                    padding={getPaddingsForButtonsByProduct(product)}
                    borderTopColor={theme.colors.stroke_400}
                >
                    <ButtonTransferMoney
                        maxWidth={
                            product === 'casino' || product === 'casinogo'  || detectMobile()
                                ? '100%'
                                : '330px'
                        }
                        disabled={
                            validateAmountToTransfer(moneyAmount, userData, bonusesData) ||
                            payoutPending ||
                            error
                        }
                        radius={'4px'}
                        onClick={() => transferMoneyHandler(exportNumberFromStringWithoutComma(moneyAmount))}
                    >
                        Udbetal til Nemkonto
                        {payoutPending && (
                            <Spinner color={'#fff'} size={'md'} />
                        )}
                    </ButtonTransferMoney>
                </WithdrawButtonsArea>
            </WithdrawWrapper>
        </ThemeProvider>
    )
}

Withdraw.propTypes = {
    currentTheme: PropTypes.string,
    product: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    bonusesData: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
    redirectUrl: PropTypes.func.isRequired,
    transferMoneyHandler: PropTypes.func.isRequired,
    payoutPending: PropTypes.bool.isRequired,
}
export default Withdraw
