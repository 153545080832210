import React, { Fragment, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import Flex from './Flex'
import PropTypes from 'prop-types'

const StyledFlex = styled(Flex)`
    background: ${({ background }) => background};
    height: 32px;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 2px;
    position: relative; /* Make it relative for the selector to position within it */
`

const SwitchButton = styled.button`
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex: 50%;
    border: none;
    background: ${({ bgColor }) => bgColor};
    color: ${(props) => (props.color ? props.color : 'white')};
    cursor: pointer;
    text-transform: capitalize;
    position: relative;
    z-index: 3;
    height: 100%;
    border-radius: 8px;

    ${(props) =>
        props.active &&
        css`
            font-weight: ${(props) => props.theme.fontWeight};
            color: ${({ activeColor }) => activeColor};
            background: ${({ activeBgColor }) => activeBgColor};
        `};
`

const Divider = styled.div`
    width: 1px;
    height: 20px;
    background: #0000000a;
    z-index: 1;
    position: relative;
    left: 1px;
`

const Selector = styled.div`
    position: absolute;
    z-index: 2;
    height: calc(100% - 4px);
    background: ${(props) => props.activeBgColor};
    border-radius: 8px;
    transition: left 0.3s, width 0.3s;
`

const SmallMultiSwitcher = ({
    value = '',
    optionList = [],
    onChange = (f) => f,
    bgColor = 'none',
    color = 'white',
    activeColor = 'white',
    activeBgColor = 'black',
    mainColor = '#0000001a',
}) => {
    const switchButtonsRef = useRef([])
    const selectorRef = useRef(null)

    useEffect(() => {
        const activeIndex = optionList.findIndex(
            (option) => option.name === value
        )
        const activeItem = switchButtonsRef.current[activeIndex]
        if (activeItem && selectorRef.current) {
            const activeWidth = activeItem.offsetWidth
            const activeLeft = activeItem.offsetLeft

            selectorRef.current.style.width = `${activeWidth}px`
            selectorRef.current.style.left = `${activeLeft}px`
        }
    }, [value, optionList])

    return (
        <StyledFlex background={mainColor}>
            {optionList.map((option, index) => (
                <Fragment key={index}>
                    <SwitchButton
                        ref={(el) => (switchButtonsRef.current[index] = el)}
                        bgColor={bgColor}
                        color={color}
                        activeBgColor={activeBgColor}
                        activeColor={activeColor}
                        active={value === option.name}
                        onClick={
                            option.action
                                ? option.action
                                : () => onChange(option.name)
                        }
                    >
                        {option.title}
                    </SwitchButton>
                    {value !== option.name && index < optionList.length - 1 && (
                        <Divider />
                    )}
                </Fragment>
            ))}
            <Selector activeBgColor={activeBgColor} ref={selectorRef} />
        </StyledFlex>
    )
}

SmallMultiSwitcher.propTypes = {
    value: PropTypes.string.isRequired,
    optionList: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    bgColor: PropTypes.string,
    color: PropTypes.string,
    activeBgColor: PropTypes.string,
    activeColor: PropTypes.string,
    mainColor: PropTypes.string,
}

export default SmallMultiSwitcher
