import React from 'react'
import Text from '../../Text'
import { PaymentTexts } from '../../../texts/PaymentTexts'
import { InfoBoxWrapper, InfoIconWrapper } from './styled'
import Icon from '../../Icon'
import InfoIcon from '../../../images/icons/info_new.svg'
import { detectMobile } from '../../../utils/deviceDetect'

const InfoPaymentNotPossible = ({theme, type = 'info'}) => {
    return (
        <InfoBoxWrapper type={type} theme={theme} direction={'row'}  align={'center'} jContent={'center'} margin={'24px 0 0 '}>
            <InfoIconWrapper height={'20px'} width={'20px'} margin={'0 15px 0 6px'}>
                <Icon
                    color={theme.info.icon_content_300}
                    height={'20px'}
                    width={'20px'}
                    src={InfoIcon}
                />
            </InfoIconWrapper>
            <Text size={13} lineHeight={'20px'} margin={'0'} color={theme.colors.black_font_900}>
                <b>{PaymentTexts.paymentNotPossible.title}</b> {` ${PaymentTexts.paymentNotPossible.text}`}
            </Text>
        </InfoBoxWrapper>
    )
}

export default InfoPaymentNotPossible
