import React from 'react'
import Text from '../../Text'
import { PaymentTexts } from '../../../texts/PaymentTexts'
import { InfoBoxWrapper, InfoIconWrapper } from './styled'
import Icon from '../../Icon'
import AttentionIcon from '../../../images/icons/attention.svg'
import { detectMobile } from '../../../utils/deviceDetect'

const InfoSendDocs = ({theme, type = 'info'}) => {
    return (
        <InfoBoxWrapper type={type} theme={theme} direction={'row'}  align={'center'} jContent={'flex-start'} margin={'24px 0 0'}>
            <InfoIconWrapper height={'24px'} width={'24px'} margin={'0 15px 0 0'}>
                <Icon
                    color={theme.info.icon_content_300}
                    height={'24px'}
                    width={'24px'}
                    src={AttentionIcon}
                />
            </InfoIconWrapper>
            <Text size={13} margin={'0'} lineHeight={'20px'} color={theme.colors.black_font_900}>
                <b>{PaymentTexts.remember.title}</b> {PaymentTexts.remember.reg1} <b>{PaymentTexts.remember.bold}</b> <br/>
                {PaymentTexts.remember.reg2}
            </Text>
        </InfoBoxWrapper>
    )
}

export default InfoSendDocs
