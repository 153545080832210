import '../styles/primitive.css'
import { defaultThemeGenerated } from './defaultThemeGenerated'
import { darkThemeGenerated } from './darkThemeGenerated'

export const defaultTheme = {
    name: 'default',
    condensed: true,
    bold: true,
    fontWeight: 700,
    colors: {
        active: '#0bc628',
        bg: '#ffffff',
        basic: '#333',
        secondary: '#333333',
        link: '#575c69',
        borderColor: '#e9eaee',
        activeBtnColor: '#fbd600',
        disabledBtnColor: '#fff8cb',
        activeLoginColor: '#fbd600',
        disabledLoginColor: '#fff8cb',
        divider: '#e9eaee',
        bgDarker: '#eceff6',
        primary: '#575c69',
        primaryAction: '#9198aa',

        ...defaultThemeGenerated,

        //colors for backward compatibility, needs to be removed
        placeholder: '#8C94A0',
        stroke_400: '#DFE5ED',
        black_font_900: '#1E1E1E',
        table_tint_300: '#F0F4FA',
        semi_grey_500: '#BFC9D9',
        bg_200: '#F5F8FC',
        bg_300: '#F8F8F8',
        red: '#F51818',
        white_100: '#fff',
        link_blue: '#196bf5',
    },
    bg: {
        default_primary: '#F5F8FC',
        info_background_100: '#EAF1FE',
    },
    texts: {
        default: {
            primary: '#2C3036',
        },
        utilities: {
            link: '#186BF5',
        },
        onAccent: {
            default: '#1E1E1E',
            inactive: '#D9D9D9',
        }
    },
    brand: {
        casino: {
            primary: '#DA1B1B',
        },
        sport: {},
        heste: {},
    },
    buttons: {
        activeBtnColor: '#FDD804',
        disabledBtnColor: '#FFF9DB',
    },
    info: {
        icon_content_300: '#186BF5',
    },
    neutral: {
        black_font_900: '#1E1E1E',
        dark_semi_grey_600: '#8C94A0',
        bg_200: '#F5F8FC',
        border: '#DFE5ED'
    },
    borders: {
        default_primary: '#E4E9EF',
        info_200: '#AFCCFA',
    },
}

export const dark = {
    name: 'dark',
    condensed: false,
    bold: false,
    fontWeight: 400,
    colors: {
        active: '#97D800',
        bg: '#121212',
        basic: '#fff',
        secondary: '#BEBEBE',
        link: '#F77705',
        borderColor: '#262626',
        activeBtnColor: '#F77705',
        disabledBtnColor: '#522200',
        activeLoginColor: '#97D800',
        disabledLoginColor: '#2C4000',
        divider: '#818181',
        bgDarker: '#262626',
        primary: '#BEBEBE',
        primaryAction: '#818181',

        ...darkThemeGenerated,
        //new colors
        placeholder: '#8C94A0',
        stroke_400: '#DFE5ED',
        red: '#F51818',
        white_100: '#fff',
        link_blue: '#196bf5',
        black_font_900: '#1E1E1E',
    },
    bg: {
        default_primary: '#F5F8FC',
        info_background_100: '#EAF1FE',
    },
    texts: {
        default: {
            primary: '#2C3036',
        },
        utilities: {
            link: '#186BF5',
        },
        onAccent: {
            default: '#1E1E1E',
            inactive: '#D9D9D9',
        }
    },
    brand: {
        casino: {
            primary: '#DA1B1B',
        },
        sport: {},
        heste: {},
    },
    buttons: {
        activeBtnColor: '#FDD804',
        disabledBtnColor: '#FFF9DB',
    },
    info: {
        icon_content_300: '#186BF5',
    },
    neutral: {
        black_font_900: '#1E1E1E',
        dark_semi_grey_600: '#8C94A0',
        bg_200: '#F5F8FC',
        border: '#DFE5ED'
    },
    borders: {
        default_primary: '#E4E9EF',
        info_200: '#AFCCFA',
    },
}
