import styled from 'styled-components'
import { boxFlex } from '../../../styled'

export const TransferMoneyModalWrapper = styled(boxFlex)`
    height: auto;
    width: 100%;
    max-width: 400px;
    padding: 42px 24px 36px;
    background: #F5F8FC;
    border-radius: 8px;
    position: relative;
    hr {
        margin: 16px auto;
        border: none;
        border-top: 1px solid #DFE5ED;
    }
`

export const TransferMoneyIconWrapper = styled(boxFlex)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 35px;
`
