import React, { Fragment, useState } from 'react'
import {
    CloseButton,
    MethodWrapper,
    ButtonContent,
    StyledModalTopbar,
    Title,
    Wrapper,
    StyledLoginButton,
    LoginWrapper,
} from './styled'
import cross from '../../images/icons/cross.svg'
import Icon from '../Icon'
import PropTypes from 'prop-types'
import Text from '../Text'
import Spinner from '../Spinner'
import mitIdLogo from '../../images/icons/mitId.svg'
import { LOGIN_TYPES, login } from '../../services/PunterService'
import { defaultTheme, dark } from '../../utils/loginThemes'
import UsernameLogin from '../UsernameLogin'
import { Divider, TextWrapper } from '../Login/styled'

const themesMap = {
    default: defaultTheme,
    dark,
}
/*
LoginDesktop is deprecated, please use ./Login instead
 */
const LoginDesktop = ({
    closeAction = () => {},
    stayOnPrevPage = false,
    beforeLogin = () => {},
    theme = 'default',
    site = '',
    oldAPI = false,
    loginByUsername = () => {},
    isFirstLogin = true,
    showResetPassword = false,
}) => {
    const [close, setClose] = useState(false)
    const [loading, setLoading] = useState({
        [LOGIN_TYPES.MIT_ID]: false,
    })

    const currentTheme = themesMap[theme]

    const loginHandler = (type) => {
        beforeLogin(type)

        if (stayOnPrevPage) {
            localStorage.setItem('stay_on_prev_page', window.location.pathname)
        }
        login(type, site, loading, (value) => setLoading(value), oldAPI)
    }

    const forgotPassword = () => {
        loginHandler(LOGIN_TYPES.MIT_ID)
    }

    return (
        <Wrapper
            close={close}
            key={`close-animation-${close}`}
            backgroundColor={currentTheme.colors.bg}
        >
            <StyledModalTopbar color={currentTheme.colors.borderColor}>
                <Title
                    color={currentTheme.colors.basic}
                    condensed={currentTheme.condensed}
                >
                    Log ind
                </Title>
                <CloseButton
                    onClick={() => {
                        setClose(true)
                        setTimeout(() => {
                            closeAction()
                        }, 200)
                    }}
                >
                    <Icon size={'sm'} src={cross} color={'#9198AA'} />
                </CloseButton>
            </StyledModalTopbar>
            <MethodWrapper>
                {!isFirstLogin ? (
                    <Fragment>
                        <LoginWrapper style={{ margin: '50px 0 30px' }}>
                            <UsernameLogin
                                currentTheme={currentTheme}
                                forgotPassword={forgotPassword}
                                loginHandler={loginByUsername}
                                showResetPassword={showResetPassword}
                            />
                        </LoginWrapper>
                        <TextWrapper>
                            <Divider color={currentTheme.colors.divider} />
                            <Text
                                padding={'0 20px'}
                                color={
                                    currentTheme.colors.primaryAction ||
                                    '#9198AA'
                                }
                                condensed={currentTheme.condensed}
                            >
                                Eller log ind med digital signatur
                            </Text>
                            <Divider color={currentTheme.colors.divider} />
                        </TextWrapper>
                    </Fragment>
                ) : null}
                <StyledLoginButton
                    onClick={() => loginHandler(LOGIN_TYPES.MIT_ID)}
                    mitId
                >
                    <ButtonContent>
                        <Icon
                            height={'14px'}
                            width={'100%'}
                            src={mitIdLogo}
                            color={'white'}
                        />

                        <Text
                            color={'white'}
                            bold={currentTheme.bold}
                            condensed={currentTheme.condensed}
                            align={'center'}
                        >
                            Log ind med MitID
                        </Text>
                        {loading[LOGIN_TYPES.MIT_ID] && (
                            <Spinner color={'#fff'} size={'md'} />
                        )}
                    </ButtonContent>
                </StyledLoginButton>
            </MethodWrapper>
        </Wrapper>
    )
}

LoginDesktop.propTypes = {
    closeAction: PropTypes.func.isRequired,
    stayOnPrevPage: PropTypes.bool,
    theme: PropTypes.string,
    site: PropTypes.string.isRequired,
    oldAPI: PropTypes.bool,
    loginByUsername: PropTypes.func.isRequired,
    isFirstLogin: PropTypes.bool,
    showResetPassword: PropTypes.bool,
}

export default LoginDesktop
