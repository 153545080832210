import React from 'react'
import {
    BonusWrapper,
    ButtonDelete,
    ButtonGoTo,
    ButtonWrapper,
    SpanWithDotBefore,
    StatusBarLine,
    StatusBarSlider,
    StatusBarValue,
    StatusBarValueWrapper,
    StatusBarWrapper,
} from './styled'
import rodLogo from '../../../images/producs-logos/casino.svg'
import trashIcon from '../../../images/icons/trash.svg'
import bet25Icon from '../../../images/icons/bet25_logo.svg'

import PropTypes from 'prop-types'
import Icon from '../../Icon'
import Text from '../../Text'
import Flex from '../../Flex'
import { numberWithCommasAndDotsIntl } from '../../../utils/formatters'
import moment from 'moment/moment'

const ActiveBonuses = ({
    type = 'W', //means casino
    onRemove = (activeBonus) => {},
    activeBonus = 0,
    rollover = 0,
    initialRollover = 0,
    redirectUrl,
    bonusButtonText = '',
    theme,
    expirationDate = 0,
    minOdds= 0
}) => {
    const turnover = Math.round(initialRollover / activeBonus)
    const statusBarPercent = 100 - (rollover / initialRollover) * 100

    const onRemoveHandler = (activeBonus) => {
        onRemove(activeBonus)
    }

    const getRedirectUrl = () => {
        if (window.location.hostname === 'localhost') {
            window.location = window.location.origin
        }

        window.location.pathname = '/casino'
    }

    const darkColor = theme.neutral.black_font_900;
    const grayColor = theme.neutral.dark_semi_grey_600;
    const logo = type === 'W' ? rodLogo : bet25Icon;
    const logoColor = type === 'W' ? theme.brand.casino.primary : darkColor;


    return (
        <BonusWrapper>
            <Flex justify={'space-between'}>
                <Icon
                    src={logo}
                    width={type === 'W' ? '100px' : '50px'}
                    height={'25px'}
                    color={logoColor}
                />
                {expirationDate > 0 && (
                    <Text size={10} lineHeigh={'16px'} margin={'0'} color={grayColor}>
                        Udløber{' '}
                        {
                            moment(
                                expirationDate,
                            ).format('DD. MMMM')
                        }
                    </Text>
                )}
            </Flex>

            <Text
                size={16}
                lineHeight={'24px'}
                color={darkColor}
                bold
                style={{ margin: '20px 0 0' }}
            >
                Indbetalingsbonus på {numberWithCommasAndDotsIntl(activeBonus)} kr.
            </Text>
            <Text
                size={10}
                lineHeight={'16px'}
                color={grayColor}
                style={{ margin: '4px 0 16px' }}
            >
                {turnover}x omsætningskrav
                {minOdds > 0 && (<SpanWithDotBefore dotColor={grayColor}>Min. odds {numberWithCommasAndDotsIntl(minOdds)} på Bet25 Sport</SpanWithDotBefore>)}
            </Text>
            <StatusBarWrapper>
                <StatusBarLine>
                    <StatusBarSlider status={statusBarPercent} />
                </StatusBarLine>
                <StatusBarValueWrapper>
                    <StatusBarValue color={darkColor}>
                        <strong>
                            {numberWithCommasAndDotsIntl(
                                Math.abs(initialRollover - rollover), true
                            )}
                        </strong>{' '}
                         / {numberWithCommasAndDotsIntl(initialRollover, true)} kr.
                    </StatusBarValue>
                    <StatusBarValue color={darkColor}>
                        -{numberWithCommasAndDotsIntl(rollover, true)} kr.
                    </StatusBarValue>
                </StatusBarValueWrapper>
                {type === 'W' && (<ButtonWrapper>
                    <ButtonGoTo
                        onClick={() => {
                            typeof redirectUrl === 'function'
                                ? redirectUrl()
                                : getRedirectUrl()
                        }}
                    >
                        {bonusButtonText ? bonusButtonText : 'Gå til alle spil'}
                    </ButtonGoTo>
                    <ButtonDelete onClick={() => onRemoveHandler(activeBonus)}>
                        <Icon
                            src={trashIcon}
                            width={'19px'}
                            height={'21px'}
                            color={'#D42C2C'}
                        />
                    </ButtonDelete>
                </ButtonWrapper>)}

            </StatusBarWrapper>
        </BonusWrapper>
    )
}

ActiveBonuses.propTypes = {
    onRemove: PropTypes.func.isRequired,
    activeBonus: PropTypes.number.isRequired,
    initialRollover: PropTypes.number.isRequired,
    redirectUrl: PropTypes.func,
    bonusButtonText: PropTypes.string,
}

export default ActiveBonuses
