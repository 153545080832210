import styled from 'styled-components'
import { detectMobile } from '../../utils/deviceDetect'
import { boxFlex } from '../../styled/index'
import Button from './../Button'


export const WithdrawWrapper = styled(boxFlex)`
    height: ${detectMobile() ? 'calc(100% - 72px)' : 'calc(100% - 56px)'};
    width: 100%;
    //max-width: 100%;
    justify-content: ${detectMobile() ? 'flex-start' : 'space-between'};
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #fff;
    padding-bottom: 72px;
`

export const WithdrawContentWrapper = styled(boxFlex)`
    width: 100%;
    overflow-y: auto;
    align-items: center;
    justify-content: flex-start;
`

export const WithdrawContent = styled(boxFlex)`
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth || '100%'};
    padding: ${({ padding }) => padding || '0'};
`

export const WithdrawButtonsArea = styled(boxFlex)`
    height: 72px;
    width: 100%;
    padding: ${detectMobile() ? '0 23px' : '0 24px'};
    border-top: ${({ borderTopColor }) => `1px solid  ${borderTopColor}`};
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #FFFFFF;
    svg {
        position: absolute;
        left: 50%;
        top: 13px;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
    @media (max-width: 767px) {
        position: fixed;
    }

`

export const WithdrawBonusesWrapper = styled(boxFlex)`
    margin: ${detectMobile() ? '28px 0 0 ' : '24px 0 0'};
    padding-bottom: 24px;
    p {
        margin-bottom: 0;
    }
`

export const ButtonTransferMoney= styled(Button)`
    color: ${({ theme }) => theme.texts.onAccent.default};
    background: ${({ theme}) =>  theme.buttons.activeBtnColor};
    &:disabled {
        color: ${({ theme }) => theme.texts.onAccent.inactive};
        background: ${({ theme }) => theme.buttons.disabledBtnColor};
        opacity: 1;
    }
`
