import styled from 'styled-components'

export const Headline = styled.div`
    text-align: center;
    font-size: 18px;
    font-weight: ${(props) => props.theme.fontWeight};
    border-bottom: 1px solid
        ${(props) => props.theme.colors.colorBorderPrimaryDefault};
    padding: 16px;
    color: ${(props) => props.theme.colors.colorTextPrimaryDefault};
`
export const Details = styled.div`
    padding: 0 24px;
`
export const Item = styled.div`
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    ${(props) =>
        !props.last &&
        'border-bottom: 1px solid ' +
            props.theme.colors.colorBorderPrimaryDefault};
    font-size: 14px;
`
export const Name = styled.div`
    font-weight: ${(props) => props.theme.fontWeight};
    color: ${(props) => props.theme.colors.colorTextPrimaryDefault};
`
export const Summary = styled.div`
    color: ${(props) => props.theme.colors.colorTextTertiaryDefault};
`
export const ActionWrapper = styled.div`
    ${(props) => console.log('props', props)}
    border-top: 1px solid ${(props) =>
        props.theme.colors.colorBorderPrimaryDefault};
    padding: 24px 16px;
`
export const Button = styled.button`
    padding: 12px;
    background: ${(props) =>
        props.theme.colors.colorBackgroundSurfacePrimaryDefault};
    border: 1px solid ${(props) => props.theme.colors.colorBorderPrimaryDefault};
    border-radius: 8px;
    font-weight: ${(props) => props.theme.fontWeight};
    font-size: 13px;
    width: 100%;
    cursor: pointer;
    color: ${(props) =>
        props.theme.colors
            .colorTextPrimaryDefault}; //--color-background-surface-primary-default
`

export const IconWrapper = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
`
