import styled from 'styled-components'
import {boxFlex} from '../../../styled'

export const WalletWrapper = styled(boxFlex)`
    height: auto;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: ${(props) => `1px solid  ${props.theme.colors.stroke_400}`} ;
    border-radius: 4px;
    background: #fff;
`

export const WalletLeft = styled(boxFlex)`
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`
export const WalletRight = styled(boxFlex)`
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-align: right;
    background-color: ${({bC}) => bC || 'transparent'};
`
export const WalletIconWrapper = styled(boxFlex)`
    height: 36px;
    width: 36px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #EAF1FE;
`
export const GrayText = styled.span`
    color: ${({color}) => color || 'gray'};
`
