import { getApiUrl } from '../config/api'
import { makeRequest } from '../utils/REST'
export const LOGIN_TYPES = {
    MIT_ID: 'mitid',
}

function sha512(message) {
    const msgBuffer = new TextEncoder().encode(message);

    return crypto.subtle.digest('SHA-512', msgBuffer).then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    });
}

export const login = (
    type,
    site = '',
    loading,
    setLoading,
    oldAPI = false,
    operation = '',
    onError = () => {}
) => {
    if (!loading[type]) {
        setLoading({ ...loading, [type]: true })
        makeRequest(
            'get',
            oldAPI
                ? `/rest/punter/mitidStart/${type}`
                : `${getApiUrl()}/punter/mitidStart/${type}`,
            {
                ...(site && { site }),
                ...(operation && { operation }),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = result.data
                } else {
                    result.errorMessage
                        ? onError(result.errorMessage)
                        : onError('Some error appear')
                }
            })
            .catch((e) => {
                setLoading({ ...loading, [type]: true })
                console.error('MitID failed: ', e)
            })
    }
}

export const restorePassword = (
    site = '',
    loading = false,
    setLoading,
    oldAPI = false,
    newPassword,
    confirmPassword,
    successHandler = () => {},
    onError = () => {}
) => {
    if (!loading) {
        setLoading(false)
        makeRequest(
            'post',
            oldAPI
                ? `/rest/punter/setPasswordSecure`
                : `${getApiUrl()}/punter/setPasswordSecure`,
            {
                edit: newPassword,
                confirm: confirmPassword,
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    successHandler()
                } else {
                    console.log('result', result)
                    onError(result)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.error('Change password is failed: ', e)
            })
    }
}

export const getAccountInformation = () => {
    return makeRequest(
        'get',
        `${getApiUrl()}/punter/getAccountInformation`
    )
        .then((res) => res.json())
        .then(res => {
            if (!res.success) {
                return Promise.reject(res.errorMessage);
            }
            return res.data;
        })
}


export const setAccountInformation = ({ email, phoneNr }) => {
    return makeRequest(
        'post',
        `${getApiUrl()}/punter/setAccountInformation`,
        {email, phoneNr}
    )
        .then((res) => res.json())
        .then(res => {
            if (!res.success) {
                return Promise.reject(res.errorMessage);
            }
            return res.data;
        })
}

export const checkExistingEmail = ({email, salt}) => {
    return sha512(email + salt).then((hash) => makeRequest(
        'post',
        `${getApiUrl()}/punter/checkEmail`,
        {email, hash}
    ))
        .then((res) => res.json())
}
