import React from 'react'
import { ThemeProvider } from 'styled-components'

import { TransferMoneyIconWrapper, TransferMoneyModalWrapper } from '../styled'
import CloseButtonIcon from '../../CloseButton/CloseButton'
import defaultSuccessIconSrc from '../../../images/icons/circleCheckmarkFilled.svg'
import Icon from '../../Icon'
import Text from '../../Text'
import Button from '../../../components/Button'
import { themesMap } from '../../../theme'
import { detectMobile } from '../../../utils/deviceDetect'
import { PopupContainer } from '../styled'

const SuccessModal = ({
    title,
    text,
    iconSrc,
    renderIcon,
    renderContent,
    closeModal = () => {},
    currentTheme = 'default',
}) => {
    const theme = themesMap[currentTheme]

    return (
        <ThemeProvider theme={theme}>
            <PopupContainer
                height={'100%'}
                justify={detectMobile() ? 'flex-end' : 'center'}
                direction={'column'}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <TransferMoneyModalWrapper>
                    <CloseButtonIcon click={closeModal} top={'36px'} />
                    <TransferMoneyIconWrapper>
                        {renderIcon ? (
                            renderIcon()
                        ) : (
                            <Icon
                                src={iconSrc || defaultSuccessIconSrc}
                                height={'112px'}
                                width={'112px'}
                            />
                        )}
                    </TransferMoneyIconWrapper>

                    {title && (
                        <Text
                            margin={'0'}
                            padding={'0'}
                            size={18}
                            lineHeight={'28px'}
                            bold
                            color={theme.colors.colorTextPrimaryDefault}
                            align={'center'}
                        >
                            {title}
                        </Text>
                    )}

                    {text && !renderContent && (
                        <Text
                            size={13}
                            lineHeight={'20px'}
                            margin={'16px 0 0'}
                            padding={'0'}
                            color={theme.colors.colorTextSecondaryDefault}
                            align={'center'}
                        >
                            {text}
                        </Text>
                    )}

                    {renderContent && renderContent()}

                    <hr />

                    <Button
                        color={theme.colors.colorTextPrimaryDefault}
                        bgColor={theme.buttons.activeBtnColor}
                        radius={'4px'}
                        onClick={closeModal}
                    >
                        OK
                    </Button>
                </TransferMoneyModalWrapper>
            </PopupContainer>
        </ThemeProvider>
    )
}

export default SuccessModal
