import styled, { css } from 'styled-components'
import { boxFlex } from '../../styled'
import { detectMobile } from '../../utils/deviceDetect'

export const PopupContainer = styled.div`
    background: ${(props) => props.theme.colors.colorBackgroundDefault};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${detectMobile() &&
        css`
            max-width: 100%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            top: auto;
            transform: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        `}
`

export const TransferMoneyModalWrapper = styled(boxFlex)`
    height: auto;
    width: 100%;
    max-width: 400px;
    padding: 42px 24px 36px;
    background: #f5f8fc;
    border-radius: 8px;
    position: relative;
    hr {
        margin: 16px auto;
        border: none;
        border-top: 1px solid #dfe5ed;
        width: 100%;
    }
`

export const TransferMoneyIconWrapper = styled(boxFlex)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 35px;
`
