import styled from 'styled-components'
import {boxFlex} from '../../../styled'

export const InfoBoxWrapper = styled(boxFlex)`
    margin: ${({margin}) => margin || '0'};
    padding: ${({ type }) => type === 'info' ? '12px 16px' : '16px 24px'};
    border: ${({ type, theme }) => `1px solid ${type === 'info' ? theme.borders.info_200 : theme.borders.default_primary}`};
    background: ${(props) => props.type === 'info' ? props.theme.bg.info_background_100 : props.theme.bg.default_primary};
    flex-direction: ${({direction}) => direction === 'row' ? 'row' : 'column'};
    align-items: ${({align}) => align || 'flex-start'};
    justify-content: ${({jContent}) => jContent || 'flex-start'};
    border-radius: 4px;
    svg {
        box-sizing: border-box;
        display: inline-block;
    }
`

export const InfoList = styled.ul`
    height: auto;
    width: 100%;
    max-width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-size: 10px;
    line-height: 16px;
    color: ${({theme}) => theme.neutral.black_font_900};
    list-style-type: none;
    li {
        padding: 0 0 0 20px;
        height: auto;
        width: 100%;
        max-width: 100%;
        margin-top: 4px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        &:before {
            content: '';
            height: 2px;
            width: 2px;
            border-radius: 100%;
            background-color: ${({theme}) => theme.neutral.black_font_900};
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    a {
        text-decoration: underline;
        text-decoration-color: ${(props) => props.theme.texts.utilities.link};
        color: ${(props) => props.theme.texts.utilities.link};
    }
    .pl-5 {
        padding-left: 5px;
    }
    img, svg {
        height: 12px;
        width: 80px;
        padding-left: 5px;
        display: inline-block;
        box-sizing: border-box;
    }
`

export const InfoListNumered = styled.ol`
    margin: 0;
    padding: 0 0 0 10px;
    list-style-type: decimal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    color: ${({theme}) => theme.neutral.black_font_900};
    .regular {
        font-weight: 400;
    }
`

export const InfoIconWrapper = styled(boxFlex)`
    height: ${({height}) => height || '18px'};
    width: ${({width}) => width || '18px'};
    justify-content: center;
    margin: ${({margin}) => margin || '0'};
`

export const InfoWrapperIconWithText = styled(boxFlex)`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
