import styled from 'styled-components'

export const ProfileCard = styled.div`
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.colorBorderPrimaryDefault};
    background: #f5f8fc;
    font-size: 14px;
    padding: 15px 20px;
    color: ${(props) => props.theme.colors.colorTextPrimaryDefault};
`

export const AddressChangeInfo = styled.div`
    font-style: italic;
    font-size: 10px;
`
