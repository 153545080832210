import styled from 'styled-components'
import { boxFlex } from '../../styled'

export const CloseButtonIconWrapper = styled(boxFlex)`
    height: 24px;
    width: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #1E1E1E;
    transition: all 0.4s ease-in-out;
    border-radius: 4px;
    transform: translateY(-50%);
    position: absolute;
    top: ${({top}) => top || '50%'};
    right: 24px;
    &:hover {
        cursor: pointer;
        background: #4F555E;
    }

`
