import React, { useState } from 'react'
import {
    ButtonWrapper,
    CancelButton,
    ConfirmButton,
    ContentWrapper,
    StyledModalTopbar,
    Wrapper,
} from './styled'

import PropTypes from 'prop-types'
import Spinner from '../../../Spinner'
import CloseButtonIcon from '../../../CloseButton/CloseButton'

const ConfirmModal = ({ bonusAmount, onConfirm, onCancel }) => {
    const [loading, setLoading] = useState(false)
    const [removeBtnHovered, setRemoveBtnHovered] = useState(false)
    const confirm = () => {
        if (!loading) {
            setLoading(true)
            onConfirm()
        }
    }

    return (
        <Wrapper>
            <StyledModalTopbar>
                Fjern bonus på {' '}
                <strong
                    style={{
                        textDecoration: removeBtnHovered
                            ? 'line-through'
                            : 'none',
                    }}
                >
                    {bonusAmount} kr.
                </strong>
                ?
                <CloseButtonIcon click={onCancel}/>
            </StyledModalTopbar>
            <ContentWrapper>
                Er du sikker på, at du vil fjerne din bonus? Denne handling kan ikke fortrydes.
                <hr/>
            </ContentWrapper>
            <ButtonWrapper>
                <CancelButton onClick={onCancel}>Annuller</CancelButton>
                <ConfirmButton
                    onClick={confirm}
                    onMouseEnter={() => setRemoveBtnHovered(true)}
                    onMouseLeave={() => setRemoveBtnHovered(false)}
                >
                    {loading ? <Spinner size={'md'} /> : 'Fjern bonus'}
                </ConfirmButton>
            </ButtonWrapper>
        </Wrapper>
    )
}

ConfirmModal.propTypes = {
    bonusAmount: PropTypes.number.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
}

export default ConfirmModal
