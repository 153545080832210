import styled from 'styled-components'
import { detectMobile } from '../../../utils/deviceDetect'

export const Wrapper = styled.div`
    width: 100%;
`

export const DescriptionWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid #377fe919;
    background: #e4effd;
    padding: 16px;
    margin: 0;

    display: flex;
    justify-content: start;
    align-items: center;
`
export const DescriptionText = styled.p`
    font-size: 16px;
    color: #333333;
    margin: 0 0 0 17px;
    padding: 0;
`

export const BonusWrapper = styled.div`
    height: auto;
    border-radius: 4px;
    border: 1px solid #edf1f8;
    background: #ffffff;
    padding: 16px;
    box-sizing: border-box;
    margin-top: 8px;
    margin-bottom: ${detectMobile() ? '20px' : '0'};
`

export const SpanWithDotBefore = styled.span`
    position: relative;
    padding-left: 12px;
    &:before {
        content: '';
        height: 2px;
        width: 2px;
        border-radius: 100%;
        background: ${({dotColor}) => dotColor || '#333333'};
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
`

export const StatusBarWrapper = styled.div`
    width: 100%;
`

export const StatusBarLine = styled.div`
    width: 100%;
    height: 10px;
    background: #F0F4FA;
    border-radius: 8px;
`

export const StatusBarSlider = styled.div`
    width: ${(props) => (props.status ? props.status + '%' : '0')};
    height: 100%;
    overflow: hidden;
    background: #0BC628;
    border-radius: 8px;
`

export const StatusBarValueWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0 0;
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin: 15px 0 0;
`
export const StatusBarValue = styled.div`
    color: ${({color}) => color || '#333333'};
    font-size: 12px;
`

export const BonusButton = styled.button`
    width: 100%;
    display: block;
    height: 46px;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
    transition: all 0.3s ease;
    cursor: pointer;
    text-align: center;
`

export const ButtonGoTo = styled(BonusButton)`
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    font-weight: bold;
    background: #FDD804;
    color: #333333;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    &:hover {
        background: #FFE659;
    }
`

export const ButtonDelete = styled(BonusButton)`
    width: 54px;
    vertical-align: middle;
    background: none;
    margin: 0 0 0 8px;

    &:hover {
        background: #fbe4e4;
    }
`
