import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import {
    ActionWrapper,
    Details,
    Headline,
    IconWrapper,
    Item,
    Name,
    Button,
    Summary,
} from './styled'
import {
    dateFormatter,
    numberWithCommasAndDotsIntl,
} from '../../../../utils/formatters'
import { PopupContainer } from '../../../Modals/styled'
import IconClose from '../../../../images/icons/close-thin.svg'
import Icon from '../../../Icon'
import { themesMap } from '../../../../theme'

const TransactionDetails = ({
    currentTheme = 'default',
    data = {},
    onClose = () => {},
}) => {
    const theme = themesMap[currentTheme]

    const {
        amount,
        transId,
        refId,
        text: type,
        transactionDate,
        product,
    } = data

    useEffect(() => {
        console.log('props', product)
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <PopupContainer
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <Headline>{type}</Headline>
                <IconWrapper onClick={onClose}>
                    <Icon
                        height={'24px'}
                        width={'24px'}
                        src={IconClose}
                        color={theme.colors.colorIconSecondaryDefault}
                    />
                </IconWrapper>
                <Details>
                    <Item>
                        <Name>Detaljer</Name>
                        <Summary>{product}</Summary>
                    </Item>
                    <Item>
                        <Name>Transaktions ID</Name>
                        <Summary>{transId}</Summary>
                    </Item>
                    <Item>
                        <Name>Reference ID</Name>
                        <Summary>{refId}</Summary>
                    </Item>
                    <Item>
                        <Name>Dato</Name>
                        <Summary>
                            {dateFormatter(
                                new Date(transactionDate).toLocaleString(
                                    'da-DK',
                                    {
                                        dateStyle: 'short',
                                        timeStyle: 'short',
                                    }
                                )
                            )}
                        </Summary>
                    </Item>
                    <Item last>
                        <Name>Beløb</Name>
                        <Summary>
                            {numberWithCommasAndDotsIntl(amount)} kr.
                        </Summary>
                    </Item>
                </Details>
                <ActionWrapper>
                    <Button
                        bgColor={
                            theme.colors.colorBackgroundSurfacePrimaryDefault
                        }
                        borderColor={theme.colors.colorBorderPrimaryDefault}
                        onClick={onClose}
                    >
                        OK
                    </Button>
                </ActionWrapper>
            </PopupContainer>
        </ThemeProvider>
    )
}

TransactionDetails.propTypes = {
    data: PropTypes.shape({}),
    onClose: PropTypes.func,
}

export default TransactionDetails
